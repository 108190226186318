const avatar_pixel_width = 81.25;

const room1_bg_width = 1906/3;
const room1_bg_height = 3000/3;
const room2_bg_width = 3813/6;
const room2_bg_height = 4026/6;
const room25_bg_width = 2912/8;
const room25_bg_height = 3740/8;
const room3_bg_width = 4642/7;
const room3_bg_height = 5000/7;
const room5_bg_width = 4549/6;
const room5_bg_height = 5494/6;
//const room6_bg_width = 4817/6;
//const room6_bg_height = 5554/6;
const room6_bg_width = 902/1.4;
const room6_bg_height = 955/1.4;
const room8_bg_width = 888/1.5;
const room8_bg_height = 973/1.5;
let target_room_after_exit_room = null;
let my_recommend_id = "";

if(!ctxmenu)
	document.addEventListener('contextmenu', event => event.preventDefault());

// Fix address bar issue on iphone SE
const appHeight = () => {
	const mw = document.getElementById("map-wrapper");
    mw.style.setProperty('--app-height', `${window.innerHeight}px`);
	const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
	const body = document.body;
    body.style.setProperty('--app-height', `${window.innerHeight}px`)
}

window.addEventListener('resize', appHeight)
appHeight()

const rooms = {
	"tree": {
		avatar_w: `${Math.round(avatar_pixel_width/tree_bg_width*100)}%`,
		bounce_cubes: ["193-176", "194-176", "193-177", "194-177"],
		def_avatar_pos: "39-47",
	},
	"entrance": {
		walk_speed: 100,
		avatar_w: "7%", // Must be the same as .grandmas in css
		avatar_w_m: "5%", // Must be the same as .grandmas in css
		def_avatar_pos: "57-77",
		def_avatar_pos_m: "57-73",
		bg: "#entrance_bg",
		blk: "#entrance_blocks",
		blk_height: 1, // must be the same as height in .entrance-blk in square.css
		cubes: entrance_all_cubes,
		mapRatio: [3526/1, 2500/1],  // height, width
		mapRatioMobile: [3526/1, 2500/1],  // height, width
		off_x: 6,
		exit_blks: [],
		"dir":"main_left",
	},
	"room1": {
		walk_speed: 150,
		avatar_w: `${Math.round(avatar_pixel_width/room1_bg_width*100)}%`,
		def_avatar_pos: "33-42",
		bg: "#room1_bg",
		blk: "#room1_blocks",
		blk_height: 2, // must be the same as height in .room1-blk in square.css
		cubes: room1_all_cubes,
		mapRatio: [room1_bg_height, room1_bg_width],  // height, width
		off_x: 6,
		exit_blks: ['37-43', '36-43','35-43', '37-44', '36-44', '35-44'],
		enter_blks: ["30-39", "31-39", "31-40"],
		tree_back_pos: "33-40",
		"dir":"main_right",
	},
	"room2": {
		walk_speed: 150,
		avatar_w: `${Math.round(avatar_pixel_width/room2_bg_width*100)}%`,
		def_avatar_pos: "33-37",
		bg: "#room2_bg",
		blk: "#room2_blocks",
		blk_height: 2, // must be the same as height in .room2-blk in square.css
		cubes: room2_all_cubes,
		mapRatio: [room2_bg_height, room2_bg_width],
		off_x: 6,
		exit_blk_range: [33, 41, 37, 41],
		enter_blks: ["1-11", "2-11", "2-12"],
		tree_back_pos: "4-12",
		"dir":"main_right",
	},
	"room25": {
		walk_speed: 150,
		avatar_w: `${Math.round(avatar_pixel_width/room25_bg_width*100)}%`,
		def_avatar_pos: room25_default_pos,
		bg: "#room25_bg",
		blk: "#room25_blocks",
		blk_height: 2, // must be the same as height in .room2-blk in square.css
		cubes: room25_all_cubes,
		mapRatio: [room25_bg_height, room25_bg_width],
		off_x: 6,
		exit_blk_range: [11, 19, 33, 37], // x1, x2, y1, y2
		enter_blks: ['2-2', '3-2','4-2', '4-3'],
		tree_back_pos: "5-4"
	},
	"room3": {
		walk_speed: 150,
		avatar_w: `${Math.round(avatar_pixel_width/room3_bg_width*100)}%`,
		def_avatar_pos: room3_default_pos,
		bg: "#room3_bg",
		blk: "#room3_blocks",
		blk_height: 2, // must be the same as height in .room3-blk in square.css
		cubes: room3_all_cubes,
		mapRatio: [room3_bg_height, room3_bg_width],
		off_x: 12,
		exit_blk_range: [7, 19, 44, 47],
		modal_blks: ["29-32", "28-32", "27-32", "29-33", "28-33", "27-33", "26-34", "25-34"],
		enter_blks: ["12-21", "13-21", '14-21'],
		tree_back_pos: "15-23",
		"dir":"main_right",
	},
	"room5": {
		walk_speed: 150,
		avatar_w: `${Math.round(avatar_pixel_width/room5_bg_width*100)}%`,
		def_avatar_pos: room5_default_pos,
		bg: "#room5_bg",
		blk: "#room5_blocks",
		blk_height: 2, // must be the same as height in .room5-blk in square.css
		cubes: room5_all_cubes,
		mapRatio: [room5_bg_height, room5_bg_width],
		off_x: 12,
		exit_blk_range: [26, 36, 53, 57], // x1, x2, y1, y2
		enter_blks: ["178-176", "178-177", "179-177", "179-176"],
		tree_back_pos: "181-177",
		"dir":"main_right",
	},
	"room6": {
		walk_speed: 150,
		avatar_w: `${Math.round(avatar_pixel_width/room6_bg_width*100)}%`,
		def_avatar_pos: room6_default_pos,
		bg: "#room6_bg",
		blk: "#room6_blocks",
		blk_height: 2, // must be the same as height in .room6-blk in square.css
		cubes: room6_all_cubes,
		mapRatio: [room6_bg_height, room6_bg_width],
		off_x: 12,
		exit_blk_range: [21, 32, 47, 49], // x1, x2, y1, y2
		enter_blks: ["244-227", "245-227"],
		tree_back_pos: "244-228",
	},
	"room8": {
		walk_speed: 150,
		avatar_w: `${Math.round(avatar_pixel_width/room8_bg_width*100)}%`,
		def_avatar_pos: "40-44",
		bg: "#room8_bg",
		blk: "#room8_blocks",
		blk_height: 2, // must be the same as height in .room8-blk in square.css
		cubes: room8_all_cubes,
		mapRatio: [room8_bg_height, room8_bg_width],
		off_x: 12,
		exit_blk_range: [39, 46, 41, 45], // x1, x2, y1, y2
		enter_blks: ["180-189", "180-190", "181-190", "181-189", "181-191"],
		tree_back_pos: "183-191",
		"dir":"main_right",
	},
};
const slide_ladder_start_positions = ["154-155", '155-155', '156-155', '154-156', '155-156', '156-156'];
const slide_ladder_end_pos = "182-173";

const categories_names = ["manga", "book", "anime", "game", "cinema", "food", "music", "place", "man", "other"];
const resize_map_to_tree_size = function() {
	$("#map").css("width", `${treeMapRatio[1]}px`);
	$("#map").css("min-width", `${treeMapRatio[1]}px`);
	$("#map").css("max-width", `${treeMapRatio[1]}px`);
	$("#map").css("height", `${treeMapRatio[0]}px`);
	$("#map").css("min-height", `${treeMapRatio[0]}px`);
	$("#map").css("max-height", `${treeMapRatio[0]}px`);
}

function character_offset_x(divId) {
	return -$('#'+divId).width() / $('#'+divId).parent().width() * 100 / 3.0;
}

function character_offset_y(divId) {
	if(_roomName != "tree" && _roomName != "entrance") {
		return rooms[_roomName]['blk_height'];
	}
	return 1.0;// must be the same as tree-blk height in square.css.
}

$(document).ready(function() {
	function preventLongPressMenu(node) {
		// if the class of node is "close", then return false
		if(node.hasClass("close")) {
			return false;
		}
		node.on('touchstart', function() {return false;});
		node.on('touchmove', function() {return false;});
		node.on('touchend', function() {return false;});
		node.on('touchcancel', function() {return false;});
	}
	preventLongPressMenu($('body img'));

	$('#login h3').on('click', function(){
		
		var i = $(this).index();
		
		$('#login h3').removeClass('active');
		$(this).addClass('active');
		
		$('#login p.btn').removeClass('show');
		$('#login p.btn').eq(i).addClass('show');
	});
});
	
function activate_menu_btn_tooltip() {
	setTimeout(function() {
		$("#menu_btn_tooltip").fadeIn();
		setTimeout(function() {
			$("#menu_btn_tooltip").fadeOut(100, function() {
				activate_menu_btn_tooltip();
			});
		}, _menu_btn_tooltip_close_timeout_secs * 1000);
	}, _menu_btn_tooltip_interval_minutes * 60000);
}

var switch_picture_timer = null;
function hide_menu_btn_tooltip() {
	// set menu_btn_tooltip_displayed to 1
	localStorage.setItem('menu_btn_tooltip_displayed', '1');
	$("#menu_btn_tooltip").hide();
	if(switch_picture_timer != null) {
		clearInterval(switch_picture_timer);
		switch_picture_timer = null;
	}
}

function menu_btn_tooltip_setup() {
/*
	if(_menu_btn_tooltip_interval_minutes == 0) {
		return;
	}
*/
    // check flag in local storage
	if(localStorage.getItem('menu_btn_tooltip_displayed') == '1') {
		return;
	}
	$("#menu_btn_tooltip").on("click", function() {
		hide_menu_btn_tooltip();
	});
	//activate_menu_btn_tooltip();
	$("#menu_btn_tooltip").fadeIn();
	// timer that switch picture of menu_btn_tooltip's child between ui_menu_pop_message_01.webp and ui_menu_pop_message_02.webp
	let i = 0;
	switch_picture_timer = setInterval(function() {
		$("#menu_btn_tooltip").find('picture source').remove();
		if(i == 0) {
			$("#menu_btn_tooltip").find("img").attr("src", _level + "files/ui_menu_pop_message_02.webp");
			i = 1;
		} else {
			$("#menu_btn_tooltip").find("img").attr("src", _level + "files/ui_menu_pop_message_01.webp");
			i = 0;
		}
	}, 10*1000);
}

function questionnaire_setup() {
	const finish_q = function(r) {
		$.ajax({
			type	: "post",
			url		: '/questionnaire',
			data	: {
				'result'		: r,
			}
		}).done(function(res) {
			if(r == 1)
				window.location.href = _questionnaire_link;
		}).fail(function(e){
		});
	}
	$("#questionnaire_button_yes").on('click', function(){
		$("#questionnaire_modal").fadeOut();
		finish_q(1);
	});
	$("#questionnaire_button_no").on('click', function(){
		$("#questionnaire_modal").fadeOut();
		// 问卷关闭后，显示直播提醒
		setTimeout(function() {
			$("#youtube_live_modal").fadeIn();
		}, 60*1000);
		finish_q(2);
	});
	$("#youtube_live_button_yes").on('click', function(){
		$("#youtube_live_modal").fadeOut();
		window.location.href = _youtube_live_link;
	});
	$("#youtube_live_button_no").on('click', function(){
		$("#youtube_live_modal").fadeOut();
	});
	// 60秒后显示问卷和直播提醒。
	setTimeout(function() {
		if(_questionnaire_link.length > 0) {
			$("#questionnaire_modal").fadeIn();
		} else {
			$("#youtube_live_modal").fadeIn();
		}
	}, 60*1000);
}

$.fn.avatar = function( map ) {
	
	const defaults = {
		level : './',
	};
	
	const _this = this;
	
	$('#chars_ul li').on('click', function() {
		//if($(this).index() != 0) { return false; }
		$("#chars_ul").find('li').removeClass('active');
		$(this).addClass('active');
		const person = $(this).find('img').attr("alt");
		const upper_c = $('#upper_colors_ul li.active a').data('num');
		const lower_c = $('#lower_colors_ul li.active a').data('num');
		$('#big_selected_avatar img').attr('src', _level+'images/avatar/'+person+'_' +upper_c+ '_' + lower_c + '/main_left.webp');
	})


	$('#upper_colors_ul li').on('click', function() {
		const person = $("#chars_ul li.active").find('img').attr("alt");
		$('#upper_colors_ul li').removeClass('active');
		$(this).addClass('active');
		const upper_c = $(this).find('a').data('num');
		const lower_c = $('#lower_colors_ul li.active a').data('num');
		$('#big_selected_avatar img').attr('src', _level+'images/avatar/'+person+'_' + upper_c + '_' + lower_c +'/main_left.webp');
	})

	$('#lower_colors_ul li').on('click', function() {
		const person = $("#chars_ul li.active").find('img').attr("alt");
		$('#lower_colors_ul li').removeClass('active');
		$(this).addClass('active');
		const lower_c = $(this).find('a').data('num');
		const upper_c =$('#upper_colors_ul li.active a').data('num');
		$('#big_selected_avatar img').attr('src', _level+'images/avatar/'+person+'_' + upper_c + '_' + lower_c +'/main_left.webp');
	})

	// set nickname
	let nickname;
	$('#avatar_choose_done').on('click', function(e){
		e.preventDefault();
		var person = $('#chars_ul li.active').find('img').attr("alt");//index();
		const upper_c = $('#upper_colors_ul li.active a').data('num');
		const lower_c = $('#lower_colors_ul li.active a').data('num');
		person = person + '_' + upper_c + '_' + lower_c;
		_userCharacter = person;
		localStorage.setItem('avatar4', _userCharacter);
		nickname = $('#nickname_input input[name="nickname"]').val();
		set_user_info(_userCharacter, nickname, function(res) {
			_this.fadeOut();
			_userName = nickname;
			$('#edit_recommend_room8 input').val(_userName );
			localStorage.setItem('nickname3', nickname);
			add_or_change_avatar(_userCharacter, nickname, cur_pos);
		});
	});
	
	$('#chars_ul_sp li').on('click', function() {
		//if($(this).index() != 0) { return false; }
		$("#chars_ul_sp").find('li').removeClass('active');
		$(this).addClass('active');
		const person = $(this).find('img').attr("alt");
		const upper_c = $('#upper_colors_ul_sp li.active a').data('num');
		const lower_c = $('#lower_colors_ul_sp li.active a').data('num');
		$('#big_selected_avatar_sp img').attr('src', _level+'images/avatar/'+person+'_' +upper_c+ '_' + lower_c + '/main_left.webp');
	})


	$('#upper_colors_ul_sp li').on('click', function() {
		const person = $("#chars_ul_sp li.active").find('img').attr("alt");
		$('#upper_colors_ul_sp li').removeClass('active');
		$(this).addClass('active');
		const upper_c = $(this).find('a').data('num');
		const lower_c = $('#lower_colors_ul_sp li.active a').data('num');
		$('#big_selected_avatar_sp img').attr('src', _level+'images/avatar/'+person+'_' + upper_c + '_' + lower_c +'/main_left.webp');
	})

	$('#lower_colors_ul_sp li').on('click', function() {
		const person = $("#chars_ul_sp li.active").find('img').attr("alt");
		$('#lower_colors_ul_sp li').removeClass('active');
		$(this).addClass('active');
		const lower_c = $(this).find('a').data('num');
		const upper_c =$('#upper_colors_ul_sp li.active a').data('num');
		$('#big_selected_avatar_sp img').attr('src', _level+'images/avatar/'+person+'_' + upper_c + '_' + lower_c +'/main_left.webp');
	})

	// set nickname
	$('#avatar_choose_done_sp').on('click', function(e){
		e.preventDefault();
		var person = $('#chars_ul_sp li.active').find('img').attr("alt");//index();
		const upper_c = $('#upper_colors_ul_sp li.active a').data('num');
		const lower_c = $('#lower_colors_ul_sp li.active a').data('num');
		person = person + '_' + upper_c + '_' + lower_c;

		_userCharacter = person;
		localStorage.setItem('avatar4', _userCharacter);
		nickname = $('#nickname_input_sp input[name="nickname"]').val();
		set_user_info(_userCharacter, nickname, function(res) {
			$("#avatar_sp").fadeOut();
			_userName = nickname;
			$('#edit_recommend_room8 input').val(_userName );
			localStorage.setItem('nickname3', nickname);
			add_or_change_avatar(_userCharacter, nickname, cur_pos);
		});
	});

	// TODO
	// every 5 min
/*
	var intervalTimer;
	intervalTimer = setInterval(function() {
		var len = $('.person .chat p').length;

		$('.person .chat').each(function(i){
			_thisChat = $(this);
			
			var len = _thisChat.find('p').length;
			var x = 0;
			if(len > 1) {
				
				_thisChat.find('p').each(function() {
					var expire = parseInt($(this).data('expire'));
					if(Date.now() > expire) { 
						
						$(this).fadeOut('fast', function(){
							$(this).remove();
						}); 

						x++;
					}
				});
			}

			console.log(x, len);
			if((x+1) >= len) { _thisChat.addClass('hide'); }
		});
		
		console.log('chat checked');
	}, 300000);
*/

	function removeCharacter(data) {
		var divId = 'user'+data.id;
		$(`#${divId}`).remove();
	}
	// 添加一个玩家，或者更新玩家的角色
	function renderCharacter(data) {
		if(data && data !== undefined) {
			//console.log(`renderCharacter ${data.position}, ${data.nickname}, ${data.character}`);
			if(data.position === undefined) { return false; }
			if(!(data.position in all_cubes)) {
				console.log(`renderCharacter invalid pos ${data.position}, ${data.nickname}, ${data.character}`);
				return;
			}
			var divId = 'user'+data.id;
/*
			if($('#'+divId).length > 0 && data.id == _userId && data.character == _userCharacter && data.nickname == _userName) {
				return;
			}
*/
			$('#'+divId).remove();
			
			var chats = '';
			var chatClass = ' hide';
				
			var positionId = data.position;
			var currentPos = positionId.split('-');
			var axis = [all_cubes[positionId].axis[0], all_cubes[positionId].axis[1]];

			if(data.chats && data.chats !== undefined) {

				if(data.chats.length > 0) { chatClass = ''; }
				$.each(data.chats, function(i, _chat) {
					var message = _chat.message;
					var className = '';
					if(_chat.is_private && _chat.is_private == 1) {
						className = 'private';
						if(data.id != _userId) { message = '会話中...'; }
					}
						
					if(className != '') { className = ' class="'+className+'"'; }
						
					var expire = (_chat.expire_at && _chat.expire_at !== undefined) ? ' data-expire="'+_chat.expire_at+'"' : '';
					chats += '<p'+className+expire+'><span>'+message+'</span></p>';
				});
			}

			var zIndex =  1001;//parseInt(currentPos[0]) + parseInt(currentPos[1]);
			var opacity = 1;
			var s = "";
			if(data.id == _userId) {
				s = "background: rgba(254,230,90,1); color: black; border: 1px solid rgba(0, 0, 0, 1)";
			}
			if(data.nickname.length == 0){
				$('#map').append('<div id="'+divId+'" class="person real-person" style="opacity: '+opacity+'; display:none; z-index: '+zIndex+'; left: '+(axis[0]-1.0)+'%; top: '+(axis[1]+1.0)+'%;">\
					<div class="wrap">\
						<div class="box">\
							<img src="'+_level+'images/avatar/'+data.character+'/main_down.webp" />\
						</div>\
					</div>\
				</div>');
			} else {
				$('#map').append('<div id="'+divId+'" class="person real-person" style="opacity: '+opacity+'; display:none; z-index: '+zIndex+'; left: '+(axis[0]-1.0)+'%; top: '+(axis[1]+1.0)+'%;">\
					<div class="wrap">\
						<div class="box">\
							<p class="name"><span style="' + s + '">'+data.nickname+'</span></p>\
							<div class="chat'+chatClass+'">\
								<p></p>\
								'+chats+'\
							</div>\
							<img src="'+_level+'images/avatar/'+data.character+'/main_down.webp" />\
						</div>\
					</div>\
				</div>');
			}
			$('#'+divId).css("width", rooms[_roomName]['avatar_w']);
			character_offset_x()
			$('#'+divId).css("left", (data.xAxis + character_offset_x(divId))+'%');
			$('#'+divId).css("top", (data.yAxis + character_offset_y(divId))+'%');
			setTimeout(function(){
				$('#'+divId).fadeIn('fast');
			}, 100);
		}
	}


	const enter_room = function(room_name, save=true, cb=null) {
		$("#zoom_in, #zoom_out").hide();
		$(".npc-pop").hide();
		$(".real-person").remove(); // 删除所有玩家
		all_cubes = rooms[room_name]['cubes'];
		let defAvatarPosKey = "def_avatar_pos";
		if($(window).width() < 640 && "def_avatar_pos_m" in rooms[room_name]) {
			defAvatarPosKey = "def_avatar_pos_m";
		}
		_socket.emit('join_room', {room_name: room_name, pos: rooms[room_name][defAvatarPosKey], character: _userCharacter, nickname: _userName} , function() {
			if(develop) {
				$(".tree-blk-dev").hide();
				$(".other-blk-dev").show();
			}
			_characterMoveInterval = rooms[room_name]['walk_speed'];
			$(".room_links").hide();
			$(".front_layer").hide();
			// Ensure user character has been added.
			if($('#user'+_userId).length <= 0) {
				d = {
					"type": "add_avatar",
					"id": _userId,
					"character": _userCharacter,
					"nickname": _userName,
					"position": rooms[room_name][defAvatarPosKey],
				}
				renderCharacter(d);
			}
			$('#user'+_userId).hide();
			$("#tree_map_objs").hide();
			$("#map").hide();
			let mapRatioKeyName = "mapRatio";
			if($(window).width() < 640 && "mapRatioMobile" in rooms[room_name])
				mapRatioKeyName = "mapRatioMobile";
			$("#map").css("width", `${rooms[room_name][mapRatioKeyName][1]}px`);
			$("#map").css("min-width", `${rooms[room_name][mapRatioKeyName][1]}px`);
			$("#map").css("max-width", `${rooms[room_name][mapRatioKeyName][1]}px`);
			$("#map").css("height", `${rooms[room_name][mapRatioKeyName][0]}px`);
			$("#map").css("min-height", `${rooms[room_name][mapRatioKeyName][0]}px`);
			$("#map").css("max-height", `${rooms[room_name][mapRatioKeyName][0]}px`);
			let avatarWidthKeyName = "avatar_w";
			if($(window).width() < 640 && "avatar_w_m" in rooms[room_name])
				avatarWidthKeyName = "avatar_w_m";
			$("#map .person").css("width", rooms[room_name][avatarWidthKeyName]);
			// 隐藏大树地图，显示房间的背景，使用房间的坐标数据
			$("#tree_blocks").hide();
			$(".tree-blk").hide();
			$(rooms[room_name]['blk']).show();
			$(rooms[room_name]['bg']).fadeIn();
			const pos = rooms[room_name][defAvatarPosKey];
			let tmp = pos.split('-');
			let axis = all_cubes[pos].axis;
			cur_pos_js = [parseInt(tmp[0]), parseInt(tmp[1])];
			cur_pos = pos;
			map.moveCharacter({
				type		: 'change_pos',
				userId 		: _userId,
				xAxis 		: axis[0], 
				yAxis 		: axis[1], 
				zIndex		: cur_pos_js[0]+cur_pos_js[1],
				avatar		: _userCharacter,
				direction 	: "up"
			});
			if('dir' in rooms[room_name]) {
				const iii = $('#user'+_userId).find('img').attr('src').lastIndexOf('/');
				$('#user'+_userId).find('img').attr('src', $('#user'+_userId).find('img').attr('src').substr(0, iii) + "/" + rooms[room_name].dir + ".webp")
			}
			$('#user'+_userId).fadeIn();
			const height = rooms[room_name][mapRatioKeyName][0];
			const width = rooms[room_name][mapRatioKeyName][1];
			_aryZoomSpX = [width/3, width/2, width];
			_aryZoomSpY = [height/3, height/2, height];
			_aryZoomX = [width/3, width/2, width];
			_aryZoomY = [height/3, height/2, height];
			_mapRatio = rooms[room_name][mapRatioKeyName];
			_zoom = 2;
			_roomName = room_name;
			if(save) {
				localStorage.setItem("room_name2", _roomName);
			}
			map.zoomMap(function() {
				map.scrollToPosition(100, function() {
					if(cb != null) {
						cb();
					}
					$("#map").show();
				});
			});
		});
	};

	function set_user_info(person, nickname, f) {
		if(nickname.length <= 0 || nickname.length > 7) {
			console.log('Invalid len', nickname.length);
			$("#nickname_input input, #nickname_input_sp input").css('color', 'red');
			$("#nickname_input, #nickname_input_sp").fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100, function() {
				$("#nickname_input input, #nickname_input_sp input").css('color', 'black');
			});
			return;
		}
		for (let i = 0; i < nickname.length; i++) {
			const result = /^[ぁ-んァ-ヶーｱ-ﾝｧ-ﾝ]+/.test(nickname[i]);
			if(!result) {
				console.log('Invalid char', nickname[i]);
				$("#nickname_input input, #nickname_input_sp input").css('color', 'red');
				$("#nickname_input, #nickname_input_sp").fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100, function() {
					$("#nickname_input input, #nickname_input_sp input").css('color', 'black');
				});
				return;
			}
		}

		$.ajax({
            type	: "post",
            url		: '/set_user_info',
            data	: {
				'user_id'		: _userId,
            	'character' 		: person,
            	'nickname'		: nickname,
            }
        }).done(function(res) {
			if(res.result != 0) {
				$("#nickname_input input, #nickname_input_sp input").css('color', 'red');
				$("#nickname_input, #nickname_input_sp").fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100, function() {
					$("#nickname_input input, #nickname_input_sp input").css('color', 'black');
				});
			} else {
				f();
			}
        }).fail(function(e){
	        console.log(e);
        });
;
	}

	function add_or_change_avatar(person, nickname, pos) {
		// 添加玩家或者更新玩家的角色
		let data = {
			"id": _userId,
			"character": person,
			"nickname": nickname,
			"position": pos,
			"type": "add_avatar",
		}
		data['position'] = pos;
		_socket.emit('action', data, function() {
			cur_pos = data.position;
			var tmpAry = cur_pos.split('-');
			cur_pos_js = [parseInt(tmpAry[0]), parseInt(tmpAry[1])];
			resize_map_to_tree_size();
			windowsBreakPoint(
				function(){
					// pc
					map.zoomMap();
					_isPC = true;
				},
				function(){
					map.zoomMap();
					_isPC = false;
			}, 640);
			// 视角移动，保证当前用户居中
			map.scrollToPosition();
			$("#map").show();
		});
	}
	// 继续上次的游戏
	function resume_game(person, nickname) 
	{
		if(develop) {
			$(".tree-blk-dev").show();
			$(".other-blk-dev").hide();
			$("#create-region-form").submit(function(){
				$.ajax({
					type	: "post",
					url		: '/build_quad_region',
					data	: {
						'cols'		: $(this).find(("input[name='cols']")).val(),
						'rows'		: $(this).find(("input[name='rows']")).val(),
						'origin_x'		: $(this).find(("input[name='origin_x']")).val(),
						'origin_y'		: $(this).find(("input[name='origin_y']")).val(),
						'off_x'		: $(this).find(("input[name='off_x']")).val(),
						'off_y'		: $(this).find(("input[name='off_y']")).val(),
						'angle'		: $(this).find(("input[name='angle']")).val(),
					}
				}).done(function(res) {
					//console.log(res);
					$("#new_block").html("");
					$.each(res.result, function(key, val) {
						$("#new_block").append(`<div data-num="${key}" class="block block${key} tree-blk" id="b${key}" style="left: ${val.axis[0]}%; top: ${val.axis[1]}%; opacity: 0.8; color: darkred;">${key}</div>`);
					});
					$("#new_block").append('');

				}).fail(function(e){
					console.log(e);
				});
				return false;
			});
			$("#del-blk-form").submit(function(){
				const x = parseInt($(this).find(("input[name='x']")).val());
				const y = parseInt($(this).find(("input[name='y']")).val());
				if(x == -1 || y == -1) {
					return false;
				}
				const blockNum = `${x}-${y}`;
				$.ajax({
					type	: "post",
					url		: '/toggle_block',
					data	: {
            			'room' 		: _roomName,
            			'blk'		: blockNum,
					}
				}).done(function(res) {
					if(res['result'] == 1)
						$(`#b${blockNum}`).css("color", "black");
					else
						$(`#b${blockNum}`).css("color", "rgba(0,0,0,0)");
				});
				return false;
			});
		}
		// 如果从角色选择界面过来的，隐藏角色选择界面。
		_this.fadeOut();
		$("#menu_btn").show();
		menu_btn_tooltip_setup();
		$("#map_overview").show();
		$("#location").show();
		// 如果已经进入了某个房间，回到这个房间
		_roomName = localStorage.getItem('room_name2') || "tree";
		if(_roomName != "tree" && _roomName in rooms) {
			enter_room(_roomName, false);
			return false;
		}
		// 恢复玩家在大树上的位置
		let pos = rooms['tree'].def_avatar_pos;
		const saved_pos = localStorage.getItem("tree_pos3");
		if(saved_pos != null) {
			pos = saved_pos;
		}
		// 进入大树
		_socket.emit('join_room', {room_name: _roomName, pos: pos, character: person, nickname: nickname} , function() {
			add_or_change_avatar(person, nickname, pos);
			questionnaire_setup();
		});
	}
	
	return {
		renderCharacter 	: renderCharacter,
		removeCharacter 	: removeCharacter,
		resume_game 	: resume_game,
		enter_room 	: enter_room,
		set_user_info: set_user_info,
	}
}

$.fn.login = function( options ) {

	var defaults = {
		level : './',
	};

	var settings = $.extend( {}, defaults, options );
	var events = {
		onLogin : function() {}
	}

	return {
		events : events
	}
}

function Graph() {
  var neighbors = this.neighbors = {}; // Key = vertex, value = array of neighbors.

  this.addEdge = function (u, v) {
    if (neighbors[u] === undefined) {  // Add the edge u -> v.
      neighbors[u] = [];
    }
    neighbors[u].push(v);
    if (neighbors[v] === undefined) {  // Also add the edge v -> u in order
      neighbors[v] = [];               // to implement an undirected graph.
    }                                  // For a directed graph, delete
    neighbors[v].push(u);              // these four lines.
  };

  return this;
}

function shortestPath(graph, source, target) {
  if (source == target) {   // Delete these four lines if
    print(source);          // you want to look for a cycle
    return;                 // when the source is equal to
  }                         // the target.
  var queue = [ source ],
      visited = { [source]: true },
      predecessor = {},
      tail = 0;
  while (tail < queue.length) {
    var u = queue[tail++],  // Pop a vertex off the queue.
        neighbors = graph.neighbors[u];
    for (var i = 0; i < neighbors.length; ++i) {
      var v = neighbors[i];
      if (visited[v]) {
        continue;
      }
      visited[v] = true;
      if (v === target) {   // Check if the path is complete.
        var path = [ v ];   // If so, backtrack through the path.
        while (u !== source) {
          path.push(u);
          u = predecessor[u];
        }
        path.push(u);
        path.reverse();
        return path;
      }
      predecessor[v] = u;
      queue.push(v);
    }
  }
  return [];
}

$.fn.virtualMap = function( options ) {
	
	const defaults = {
		level : './',
		dragSpeed : 8
	};

	const settings = $.extend( {}, defaults, options );
	
	const _this = this;
	const _parent = settings.parentDiv;

	let _dragSpeed = ((settings.dragSpeed * -1) * 0.1).toFixed(2);
	_dragSpeed = parseFloat(_dragSpeed);

	// drag
	let clicked = false;
	let _isMoving = false;
	
	const events = {
		moveDone : function() {
		}
	}
	

	// zoom in
	$('p.zoom_in').on('click', function(){
		if(_zoom < 2 && _isMoving == false) {
			_zoom++;
			zoomMap(function() {
				scrollToPosition();
			});
		}
		
		return false;
	});

	// zoom out
	$('p.zoom_out').on('click', function(){
		if(_zoom > 0 && _isMoving == false) {
			_zoom--;
			zoomMap(function() {
				scrollToPosition();
			});
		}
		
		return false;
	});

	// ratio
	function zoomMap(cb=null) {
		if($(window).width() < 640) {
			zoom(_aryZoomSpX[_zoom], _aryZoomSpY[_zoom], cb);
		} else {
			zoom(_aryZoomX[_zoom], _aryZoomY[_zoom], cb);
		}
		const avatar_name_font_sizes = ['6px', '12px', '24px'];
		const npc_pop_p_font_sizes = ['4px', '8px', '16px'];
		$("#map .person p.name span").css('font-size', avatar_name_font_sizes[_zoom])
		$(".npc-pop p").css('font-size', npc_pop_p_font_sizes[_zoom])
		return false;
	}

	let centerX, centerY;
	let percentX, percentY;
	let scrollX, scrollY;
	function zoom(zoomX, zoomY, cb=null) {
		centerX = _parent.innerWidth() / 2;
		centerY = _parent.innerHeight() / 2;
		
		percentX = parseFloat( (((centerX - _this.offset().left) / _this.width())  * 100).toFixed(1))
		percentY = parseFloat( (((centerY - _this.offset().top)  / _this.height())  * 100).toFixed(1))
		
		scrollX = Math.round((percentX/100) * zoomX) - centerX;
	
		let height =  zoomX / _mapRatio[1] * _mapRatio[0];
		scrollY = Math.round((percentY/100) * height) - centerY;
		//console.log("centerX", centerX, "centerY", centerY, "percentX", percentX, "percentY", percentY)
/*
		_this.parent('#map-wrapper').animate({
	       scrollTop: scrollY,
	       scrollLeft: scrollX
	    }, 500, "linear");
*/

/*
	    _this.animate({
	       width: zoomValue+'px'
	    }, 500, "linear");
*/

		//console.log("zoom", zoomX, zoomY);
	    gsap.to(_this, {
			width : zoomX, height: zoomY, "min-width": zoomX, "max-width": zoomX, "min-height": zoomY, "max-height": zoomY, duration: 0, ease: 'linear',
			onComplete: function() {
				gsap.to(_this.parent('#map-wrapper'), {
					scrollTop : scrollY, scrollLeft : scrollX, duration: 0, ease: 'linear',
					onComplete: function() {
						if(cb != null) {
							cb();
						}
					}
				});

			}
		});
	}

	var _aryDirection = [''];

	let pageX, pageY, startX, startY;
	let mapX, mapY;
	_this.on({
		'mousemove' : function(e) {
			if(clicked == true) {
				pageX = e.pageX - startX;
				pageY = e.pageY - startY;

				_parent.scrollLeft(mapX + (pageX * _dragSpeed));
				_parent.scrollTop(mapY + (pageY * _dragSpeed));
			}
		},
		'mousedown' : function(e) {
			clicked = true;
			
			startX = e.pageX;
			startY = e.pageY;
			
			mapX = _parent.scrollLeft();
			mapY = _parent.scrollTop();
	
			//percentX = parseFloat( (((e.pageX - _this.offset().left) / _this.width())  * 100).toFixed(1) )
			// percentY = parseFloat( (((e.pageY - _this.offset().top)  / _this.height())  * 100).toFixed(1) )

			_this.css('cursor', 'grab');
			e.preventDefault();
		},
		'mouseup' : function() {
			
			clicked = false;
			_this.css('cursor', 'pointer');
		},
		'mouseleave': function() {
			clicked = false;
			_this.css('cursor', 'pointer');
		}
	});

	let isComputing = false;
	let _desPosition = [];
	let _aryDirectionPriority;
	
	let blockNum;
	let aryNum;

	$('.blocks .block').on('click', function(e) {
		$(".npc-pop").hide();
		if(develop) {
			const that = $(this);
			blockNum = $(this).data('num');
			$.ajax({
				type	: "post",
				url		: '/toggle_block',
				data	: {
            		'room' 		: _roomName,
            		'blk'		: blockNum,
				}
			}).done(function(res) {
				if(res['result'] == 1)
					that.css("color", "black");
				else
					that.css("color", "rgba(0,0,0,0)");
			});

			return;
		}	

		if(!_userId || _userId === undefined) { return false; }
		if(_roomName == "entrance") {
			// 如果grandma_talk已经显示，靠近老婆婆的blk不能响应点击
			if($("#grandma_talk").is(":visible")) {
				blockNum = $(this).data('num');
				const blk_pos_x = Math.round((all_cubes[blockNum].axis[0] / 100)  * $("#map").width());
				const blk_pos_y = Math.round((all_cubes[blockNum].axis[1] / 100)  * $("#map").height());
				let min_dist_y = 500, min_dist_x = 400;
				if($(window).width() < 640) {
					min_dist_y = 400;
					min_dist_x = 300;
				}
				const grandma_y = $("#grandma").position().top;
				const grandma_x = $("#grandma").position().left;
				if(Math.abs(grandma_y - blk_pos_y) <= min_dist_y && Math.abs(grandma_x - blk_pos_x) <= min_dist_x) {
					return;
				}
			}
		}
		if(isComputing == false) {

			blockNum = $(this).data('num');
			//console.log('click', blockNum);
			aryNum = blockNum.split('-');
			if(cur_pos_js[0] == aryNum[0] && cur_pos_js[1] == aryNum[1])
				return;

			isComputing = true;
			if(_roomName == "tree") {
				save_my_pos(blockNum);
			}
			var start_region = 0, end_region = 0;
			if(_roomName == "tree") {
				start_region = all_cubes[cur_pos_js[0]+'-'+cur_pos_js[1]].region;
				end_region = all_cubes[aryNum[0]+'-'+aryNum[1]].region;
			}
			if(start_region == end_region) {
				_desPosition = [parseInt(aryNum[0]), parseInt(aryNum[1])];
				checkPriorityDirection(cur_pos_js);
				compoute_path_and_move();
			} else {
				_cross_region_routes = [];
				let path = [];
				if(`${start_region}->${end_region}` in tree_map_inter_region_paths) {
					// 快捷路径
					path = tree_map_inter_region_paths[`${start_region}->${end_region}`];
				} else {
					// 寻找直接的连接
					if(`${start_region}->${end_region}` in tree_map_interfaces)
					{
						path = [[start_region, end_region]];
					} else {
						/*
						// 寻找中间的region
						$.each(tree_map_interfaces, function(key, val) {
							if(key.startsWith(`${start_region}->`)) {
								const tmp = key.split('->');
								const inter_region = tmp[1];
								if(`${inter_region}->${end_region}` in tree_map_interfaces) {
									path = [[start_region, inter_region], [inter_region, end_region]];
									return false;
								}
							}
						});
						*/
						var graph = new Graph();
						$.each(Object.keys(tree_map_interfaces), function(i, key) {
							const tmp = key.split('->');
							graph.addEdge(parseInt(tmp[0]), parseInt(tmp[1]));
						});
						const shortest_path = shortestPath(graph, start_region, end_region);
						if(shortest_path.length > 0) {
							shortest_path.forEach(function(region, i) {
								if(i < shortest_path.length - 1) {
									path.push([region, shortest_path[i+1]]);
								}
							});
						}
					}
				}
				if(path.length > 0)
				{
					// Shortcut
					$.each(path, function(i, rtrpath) {
						if(i == 0) {
							// 先移到当前region的出口
							const cur_region_pos = tree_map_interfaces[`${rtrpath[0]}->${rtrpath[1]}`][0];
							if(cur_pos != cur_region_pos) {
								//console.log(`Move to outlet of region ${start_region}, from ${cur_pos} to ${cur_region_pos}`);
								const tmp = cur_region_pos.split('-');
								_desPosition = [parseInt(tmp[0]), parseInt(tmp[1])];
								checkPriorityDirection(cur_pos_js);
								compoute_path_and_move(true);
							}
						}
						// 进入下一个region
						_cross_region_routes.push(tree_map_interfaces[`${rtrpath[0]}->${rtrpath[1]}`][1]);

						if(i == path.length - 1) {
							if(blockNum != tree_map_interfaces[`${rtrpath[0]}->${rtrpath[1]}`][1]) {
								// 在最终目的region内移动
								const saved_cur_pos_js = cur_pos_js;
								const tmp = tree_map_interfaces[`${rtrpath[0]}->${rtrpath[1]}`][1].split('-');
								cur_pos_js = [tmp[0], tmp[1]];
								_desPosition = [parseInt(aryNum[0]), parseInt(aryNum[1])];
								//console.log(`Move in final dest region ${start_region}, from ${tree_map_interfaces[`${rtrpath[0]}->${rtrpath[1]}`][1]} to ${blockNum}`);
								checkPriorityDirection(cur_pos_js);
								compoute_path_and_move(true);
								cur_pos_js = saved_cur_pos_js;
							}
						} else {
							// 在intermediate region的入口移到出口
							const next_rtr_path = path[i+1];
							//console.log(`Move in intermediate region ${rtrpath[1]}`, next_rtr_path);
							const saved_cur_pos_js = cur_pos_js;
							const tmp = tree_map_interfaces[`${rtrpath[0]}->${rtrpath[1]}`][1].split('-');
							cur_pos_js = [tmp[0], tmp[1]];
							const tmp2 = tree_map_interfaces[`${next_rtr_path[0]}->${next_rtr_path[1]}`][0].split('-');
							_desPosition = [parseInt(tmp2[0]), parseInt(tmp2[1])];
							checkPriorityDirection(cur_pos_js);
							compoute_path_and_move(true);
							cur_pos_js = saved_cur_pos_js;
						}
					});
					isComputing = false;
					_socket.emit('action', {
								type		: 'change_pos',
								oneStep		: false,
								userId 		: _userId,
								route 		: _cross_region_routes,
								avatar		: _userCharacter,
								originalPos	: cur_pos,
								room_name : _roomName,
					});
				} else {
					isComputing = false;
				}
			}
		}
	});

	
	let _start, _end;
	let _checkedPos = [];
	let _checkingPos = [];
	let _aryCheckedPos = [];
	let _found = false;
	let _lastPosition = null;
	let _route;
	let _aryInterval = {};
	let _cross_region_routes = [];

	function compoute_path_and_move(compute_only=false) {
		_checkedPos = new Array();
		_aryCheckedPos = new Array();
		_checkingPos = new Array();
		_route = new Array();
		_found = false;
		_lastPosition = null;

		if(cur_pos_js[0] != _desPosition[0] || cur_pos_js[1] != _desPosition[1]) {
			_start = cur_pos_js[0]+'-'+cur_pos_js[1];
			_end = _desPosition[0]+'-'+_desPosition[1];
			pathFinder(_start, true, function(){

				_route.push(_lastPosition);
				generateRoute(_lastPosition, function(){
					_route.splice(-1);
					_route.reverse();
					_route.push(_desPosition[0]+'-'+_desPosition[1]);
					isComputing = false;
					let route = cleanRoute(_route);
					//console.log('send change_pos from generateRoute', route);
					if(!compute_only) {
						_socket.emit('action', {
							type		: 'change_pos',
							oneStep		: false,
							userId 		: _userId,
							route 		: route,
							avatar		: _userCharacter,
							originalPos	: _start,
							room_name : _roomName,
						});
					} else {
						_cross_region_routes = _cross_region_routes.concat(route);
					}
				});				
			});
		}
		else {
			isComputing = false;
		}
	}

	let newRoute, jump, aryJump;
	function cleanRoute(route) {

		newRoute = []; 
		jump = false;
		aryJump = jump_cubes;

		$.each(route, function(i, positionId) {
		
			if(jump == positionId) { jump = false; }
	
			if(jump == false) {
				newRoute.push(positionId);
			}

			if(aryJump[positionId] !== undefined) {
				jump = jump_cubes[positionId][0];
				aryJump = [];
			}
		});

		return newRoute;
	} 
	let scroll_allowed = true;
	function startMove(data) {
		if(data.route && data.route !== undefined) {
			var route = data.route;
			var userId = data.userId;

			var i = 0;
			// if(data.oneStep == false && userId == _userId) { _isMoving = true; }

			moveBeat(i, data);
			i++;

			clearInterval(_aryInterval['user'+userId]);
			
			let direction;
			let face;
			_aryInterval['user'+userId] = setInterval(function(){
				if(!scroll_allowed)
					return;
				// move done
				//console.log('Move', i);
				if(i >= route.length) {
					clearInterval(_aryInterval['user'+userId]);
					if(_userId == data.userId) 
					{	
						if(data.oneStep == false) {
							_isMoving = false;
						}
						else {
							if(_roomName == "tree")
								save_my_pos(route[i-1]);
						}
						direction = $('#user'+data.userId).data('direction');
						$('#user'+data.userId).data('direction', '');
						//console.log('move done, dir', direction)
						$('#user'+data.userId).find('.box img').attr('src', _level+'images/avatar/'+data.avatar+'/main_'+direction+'.webp');
						events.moveDone(route[i-1]);
					} else {
						direction = $('#user'+data.userId).data('direction');
						$('#user'+data.userId).data('direction', '');
						//console.log('move done, dir', direction)
						$('#user'+data.userId).find('.box img').attr('src', _level+'images/avatar/'+data.avatar+'/main_'+direction+'.webp');
					}

					return false;
				}
				moveBeat(i, data, function(){
					if(_roomName == "entrance") {
						if($("#grandma").is(":visible") && $(".real-person").is(":visible")) {
							const grandma_y = $("#grandma").position().top;
							const user_y = $("#user" + _userId).position().top;
							const grandma_x = $("#grandma").position().left;
							const user_x = $("#user" + _userId).position().left;
							//console.log(`${user_x}, ${user_y} - ${grandma_x}, ${grandma_y}`);
							let min_dist_y = 500, min_dist_x = 400;
							if($(window).width() < 640) {
								min_dist_y = 400;
								min_dist_x = 300;
							}
							if(Math.abs(grandma_y - user_y) <= min_dist_y && Math.abs(grandma_x - user_x) <= min_dist_x && !$("#grandma_talk").is(":visible")) {
								scroll_allowed = false;
								//console.log("grandma_talk show");
								clearInterval(_aryInterval['user'+userId]);
								$("#grandma_talk").show();
								$('#grandma_talk_inner3').hide();
								$('#grandma_talk_inner2').hide();
								$('#grandma_talk_inner1').show();
								setTimeout(function() {
									const blockNum = $("#eb62-60").data('num');
									const userOffSetLeft = Math.round( ((all_cubes[blockNum].axis[0] / 100)  * $("#map").width()) - $('#map-wrapper').width() / 2 );
									let userOffSetTop = Math.round( ((all_cubes[blockNum].axis[1] / 100)  * $("#map").height()) - $('#map-wrapper').height() / 2 );
									userOffSetTop -= 128;
									$('#map-wrapper').animate({scrollTop: userOffSetTop, scrollLeft : userOffSetLeft}, 1500);
									setTimeout(function() {
										scroll_allowed = true;
									}, 5000);
								}, 100);
							}
							if((Math.abs(grandma_y -user_y) > min_dist_y || Math.abs(grandma_x - user_x) > min_dist_x) && $("#grandma_talk").is(":visible")) {
								//console.log("grandma_talk hide");
								$("#grandma_talk").hide();
							}
						}
					}
				});
				i++;
			}, _characterMoveInterval);
		}
	}


	function moveBeat(i, moveData, cb=null) {
		//let positionId = moveData.route[i];
		//console.log("pos", moveData.route[i]);
		let tmp = moveData.route[i].split('-');
		let axis = all_cubes[moveData.route[i]].axis;
		let currentPos = [parseInt(tmp[0]), parseInt(tmp[1])];

		if(moveData.userId == _userId) {

			cur_pos = moveData.route[i];
			cur_pos_js = currentPos;
			// _isMoving = false; 
			if(moveData.oneStep == true) {
				scrollToPosition(400);
			}
		}
		
		let prevIndex = i-1;
		let direction = 'left';
		//console.log('moveBeat decide direction', moveData, currentPos);
		if(moveData.route[prevIndex] !== undefined) {

			direction = getDirection(moveData.route[prevIndex], currentPos);
		}
		else {
			direction = getDirection(moveData.originalPos, currentPos);
		}
		moveCharacter({
			type		: 'change_pos',
			userId 		: moveData.userId,
			xAxis 		: axis[0], 
			yAxis 		: axis[1], 
			zIndex		: currentPos[0]+currentPos[1],
			avatar		: moveData.avatar,
			direction 	: direction
		}, true, true, cb);

		return false;
	}

	function generateRoute(lastPosition, _callback = function(){ }) {

		if(lastPosition != _start) {
			$.each(_aryCheckedPos, function(i, path){
				if(path.neighbor.includes(lastPosition)) {
					_route.push(path.id);
					generateRoute(path.id);
					return false;
				}
			});
		}

		_callback();
	}
	
	function getDirection(currentPos, nextPos) {
	   // 过桥时候的方向
	   const key = `${currentPos},${nextPos[0]}-${nextPos[1]}`;
	   if(key in tree_special_path_directions) {
		   const dirs = ['left', 'right', 'up', 'down'];
		   return dirs[tree_special_path_directions[key]]
	   }
		currentPos = currentPos.split('-');
		let x = nextPos[0];
		let y = nextPos[1];
		
		let direction = '';
		switch(true) {
			case (currentPos[0] == x && currentPos[1] < y) : // down
				direction = 'down';
			break;
			case (currentPos[0] < x && currentPos[1] == y) 	: // left
			case (currentPos[0] < x && currentPos[1] < y) 	: // down left
			case (currentPos[0] < x && currentPos[1] > y) 	: // upleft
				direction = 'left';
			break;
			case (currentPos[0] == x && currentPos[1] > y) : // up
				direction = 'up';
			break;
			case (currentPos[0] > x && currentPos[1] > y) 	: // upright
			case (currentPos[0] > x && currentPos[1] == y) 	: // right
			case (currentPos[0] > x && currentPos[1] < y) : // downRight
				direction = 'right';
			break;
		}

		return direction;
	}

	function checkPriorityDirection(currentPos) {

		var x = _desPosition[0];
		var y = _desPosition[1];
		switch(true) {
			case (currentPos[0] == x && currentPos[1] < y) :
				// going down
				_aryDirectionPriority = ['down', 'downLeft', 'downRight', 'left', 'right', 'upLeft', 'upRight', 'up'];
			break;
			case (currentPos[0] < x && currentPos[1] < y) :
				// down left
				_aryDirectionPriority = ['down', 'downLeft', 'left', 'upLeft', 'downRight', 'up', 'right', 'upRight'];
			break;
			case (currentPos[0] < x && currentPos[1] == y) :
				// left
				_aryDirectionPriority = ['left', 'downLeft', 'upLeft', 'up', 'down', 'upRight', 'downRight', 'right'];
			break;
			case (currentPos[0] < x && currentPos[1] > y) :
				// upleft
				_aryDirectionPriority = ['up', 'left', 'upLeft', 'upRight', 'downLeft', 'right', 'down', 'downRight'];
			break;
			case (currentPos[0] == x && currentPos[1] > y) :
				// up
				_aryDirectionPriority = ['up', 'upLeft', 'upRight', 'left', 'right', 'downLeft', 'downRight', 'down'];
			break;
			case (currentPos[0] > x && currentPos[1] > y) :
				// upright
				_aryDirectionPriority = ['up', 'upRight', 'right', 'upLeft', 'down', 'downRight', 'left', 'downLeft'];
			break;
			case (currentPos[0] > x && currentPos[1] == y) :
				// right
				_aryDirectionPriority = ['right', 'upRight', 'downRight', 'up', 'down', 'upLeft', 'downLeft', 'left'];
			break;
			case (currentPos[0] > x && currentPos[1] < y) :
				// downRight
				_aryDirectionPriority = ['down', 'right', 'downRight', 'left', 'upRight', 'downLeft', 'up', 'upLeft'];
			break;
		}
		
		return false;
	}

	function checkNeighbor(x, y) {
	
		var aryNeighbors = new Array();
		
		var neighbor = (x+1)+'-'+y;

		$.each(_aryDirectionPriority, function(i, direction){

			switch(direction) {
				case 'left'		: neighbor = (x+1)+'-'+y; break;
				case 'up' 		: neighbor = x+'-'+(y-1); break;
				case 'down' 	: neighbor = x+'-'+(y+1); break;
				case 'right' 	: neighbor = (x-1)+'-'+y; break;
				case 'upRight'	: neighbor = (x-1)+'-'+(y-1); break;
				case 'upLeft' 	: neighbor = (x+1)+'-'+(y-1); break;
				case 'downLeft'	: neighbor = (x+1)+'-'+(y+1); break;
				case 'downRight': neighbor = (x-1)+'-'+(y+1); break;
			}

			if(all_cubes[neighbor] && all_cubes[neighbor] !== undefined) { aryNeighbors.push(neighbor); }
		});

		return aryNeighbors;
	}

	function pathFinder(position, isFirst = false, _callback = function() {}) {

		var aryPos = position.split('-');
		var x = parseInt(aryPos[0]);
		var y = parseInt(aryPos[1]);

		if(_checkedPos.includes(position) == false) {

			checkPriorityDirection(position.split('-'));

			var checkingPos = {
				id 			: position,
				neighbor 	: checkNeighbor(x, y)
			};

			_checkedPos.push(position);
			_aryCheckedPos.push(checkingPos);

			$.each(checkingPos.neighbor, function(i, data){

				if(data == _end && _found == false) {
					_lastPosition = position;
					_found = true;

					return false;
				}

				if(_checkedPos.includes(data) == false) {
					if(_found == false) {
						var tmp = pathFinder(data);	
			
						_aryCheckedPos.push(tmp);
					}
				}
			});

			if(isFirst == true) {
				_callback();
			}

			return checkingPos;
		}	
		return null;
	}

	function getUserPosition() {
		try {
			if(_userId && _userId !== undefined) { 
				var x = parseFloat( ((($('#user'+_userId).offset().left - _this.offset().left) / _this.width())  * 100).toFixed(1) ) + 0.5;
				var y = parseFloat( ((($('#user'+_userId).offset().top - _this.offset().top) / _this.height())  * 100).toFixed(1) );
	
				return [x, y];
			}
		}
		catch(e) {
			console.log(e);
		}
		
	}


	function moveCharacter(data, scrollToUser=true, use_gasp_duration=true, cb=null) {
		if(!scroll_allowed) return;
		if(!data.userId || data.userId === undefined) { return false; }
		
		let opacity = 1;
		gsap.to($('#user'+data.userId), {
			left : (data.xAxis + character_offset_x('user'+data.userId))+'%', 
			top : (data.yAxis + character_offset_y('user'+data.userId))+'%', 
			duration: (gsap_duration*0.001), 
			ease: 'linear',
			onStart: function() {
				if(scrollToUser && _userId == data.userId && _roomName != "entrance") {
					if(use_gasp_duration)
						scrollToPosition(gsap_duration);
					else
						scrollToPosition(0);
				}
			},
			onComplete: function() {
				if(scrollToUser && _userId == data.userId && _roomName == "entrance") {
					scrollToPosition(gsap_duration, cb);
				} else {
					if(cb != null)
						cb();
				}
			}
		});

		if($('#user'+data.userId).data('direction') !== data.direction) {
			$('#user'+data.userId).data('direction', data.direction)
			$('#user'+data.userId).find('.box img').attr('src', _level+'images/avatar/'+data.avatar+'/walk_'+data.direction+'.webp');
		}
	}
	

	// go to current location
	$('#loc_btn').on('click', function() {
		scrollToPosition();
	});

	const restart_game = function() {
		localStorage.removeItem("avatar2");
		localStorage.removeItem("nickname3");
		localStorage.removeItem("room_name2");
		localStorage.removeItem("tree_pos3");
		localStorage.removeItem('show_entrance1');
		$.ajax({
				type	: "post",
				url		: '/reset_me',
				data	: {
				}
			}).done(function(res) {
				window.location.href = "/";
			}).fail(function(e){
		});
	}

	var end_game_step = 1;
	var end_game_timer_id = null;
	const step2 = function() {
		end_game_step = 2;
		$("#end_game_skip_btn").hide();
		$.ajax({
            type	: "post",
            url		: '/end_game_pic',
            data	: {}
        }).done(function(res) {
			$('#end_game_bk_pic source').remove();
			$("#end_game_bk_img").attr("src", _level + "files/c02_anim_Half_v05.webp");
			$('#end_game_msg2 picture source').remove();
			$("#end_game_msg2 img").attr("src", _level + "files/end_anim_c02_serifu/WEBP/" + res.result);
			$("#end_game_msg2").fadeIn();
			end_game_timer_id = setTimeout(function() {
				$("#end_game_msg2").hide();
				$("#end_game_bk_img").hide();
				$('#end_game_bk_pic source').remove();
				$("#end_game_bk_img").attr("src", _level + "files/c03_anim_Half_v05.webp");
				$("#end_game_bk_img").show();
				end_game_timer_id = setTimeout(function() {
					$("#end_game_bk_img").hide();
					$("#map").fadeOut(3000, restart_game);
				}, 3*1000);
			}, 6*1000);
        }).fail(function(e){
        });

	}
	$('#room1_logout_btn, #room1_logout_btn2, #logout_btn').on('click', function() {
		$("#menu").removeClass('show');
		$("#end_game_confirm_modal").fadeIn();
	});
	$("#end_yes").on('click', function() {
		$("#end_game_confirm_modal").hide();
		$("#end_game").show();
		end_game_timer_id = setTimeout(function() {
			if(end_game_step != 2) {
				step2();
			}
		}, 11*1000);
	});
	$("#end_no").on('click', function() {
		//$('#end_game picture source').remove();
		//$("#end_game img").attr("src", `${_level}files/c01_anim_Half_v05.webp?v=${Math.random()}`);
		$("#end_game_confirm_modal").hide();
	});

	$("#end_game_skip_btn").on('click', function() {
		if(end_game_timer_id != null)
			clearTimeout(end_game_timer_id);
		step2();
	});
	/*
	$("#end_game .my-overlay").on('click', function() {
		if(end_game_timer_id != null)
			clearTimeout(end_game_timer_id);
		$("#end_game").hide();
	});
	*/

	$(document).keydown(function(e){
		/*
		switch (e.which) {
			case 38 : 
				move('up'); 
				break;
			case 37 : 
				move('left'); 
				break;
			case 39 : 
				move('right'); 
				break;
			case 40 : 
				move('down');
				break;			
		}
		*/
		if(e.which == 37 || e.which == 38 || e.which == 39 || e.which == 40 ) {
			e.preventDefault();
			return false;
		}
	});

	// scroll to character's position
	function scrollToPosition(speed = 100, cb=null) {
		if(!scroll_allowed) return;
		if(all_cubes[cur_pos] !== undefined) {
			let userOffSetLeft, userOffSetTop;
			// _this.parent('#map-wrapper').width()/_this.parent('#map-wrapper').height() 是屏幕宽度和屏幕高度
			// _this.width()是背景图的宽度，大于屏幕宽度
			userOffSetLeft = Math.round( ((all_cubes[cur_pos].axis[0] / 100)  * _this.width()) - _this.parent('#map-wrapper').width() / 2 );
			let y_off = _this.parent('#map-wrapper').height() / 2;
			if(_roomName == "entrance")
				y_off = _this.parent('#map-wrapper').height() / 1.2;
			userOffSetTop = Math.round( ((all_cubes[cur_pos].axis[1] / 100)  * _this.height()) -  y_off);
			//console.log(`scrollToPosition speed ${speed}, userOffSetTop: ${userOffSetTop}, userOffSetLeft: ${userOffSetLeft}`);
			if(speed == 0) {
				_this.parent('#map-wrapper').scrollTop(userOffSetTop);
				_this.parent('#map-wrapper').scrollLeft(userOffSetLeft);
				if(cb != null)
					cb();
			} else {
				gsap.to(_this.parent('#map-wrapper'), {
					scrollTop : userOffSetTop, 
					scrollLeft : userOffSetLeft, 
					duration: (speed*0.001), 
					ease: 'linear',
					onComplete: cb
				});
			}
		} else {
			console.log(`${cur_pos} not found`);
		}
	}
	
	function scrollToPosition3(x, y, speed = 100, cb = null) {
		gsap.to(_this.parent('#map-wrapper'), {
				scrollTop : y, 
				scrollLeft : x, 
				duration: (speed*0.001), 
				ease: 'linear',
				onComplete: cb
			});
	}

	// move self
	function move(direction) {
		if(_isMoving == false) {
			//console.log('Move avatar', direction);

			var x = cur_pos_js[0];
			var y = cur_pos_js[1];

			switch (direction) {
				case 'up' 		: y--; break;
				case 'left' 	: x++; break;
				case 'right' 	: x--; break;
				case 'down' 	: y++; break;	

				case 'upleft' : y--; x++; break;	
				case 'upright' : y--; x--; break;		
				case 'downleft' : y++; x++; break;		
				case 'downright' : y++; x--; break;		
			}

			var newXY = x+'-'+y;

			if(all_cubes[newXY] && all_cubes[newXY] !== undefined) {

				var route = [newXY];
				if(jump_cubes[newXY] !== undefined) {
					route = [newXY, jump_cubes[newXY][0], jump_cubes[newXY][1]];
				}
				
				var data = {
					type		: 'change_pos',
					oneStep		: true,
					userId 		: _userId,
					route 		: route,
					avatar		: _userCharacter,
					originalPos	: cur_pos,
					room_name : _roomName,
				}
				_isMoving = true;
				_socket.emit('action', data);

				setTimeout(function(){ _isMoving = false; }, 400);
			}
			else {
				console.log('Position not found');
			}
		}
	}

	// handles ajax
	function save_my_pos(positionId) {
		localStorage.setItem("tree_pos3", positionId);
	}

	return {
		events: events,
		startMove : startMove,
		zoomMap : zoomMap,
		scrollToPosition : scrollToPosition,
		scrollToPosition3: scrollToPosition3,
		moveCharacter: moveCharacter,
		save_my_pos: save_my_pos,
	}
}

let my_tweet_id = "";
const show_edit_tweet = function() {
	$.ajax({
        type	: "post",
        url		: '/my_tweet',
        data	: {
        }
    }).done(function(res) {
		//console.log(res.result);
		my_tweet_id = res.result._id;
		$('#edit_tweet_room3 input').attr("value", _userName );
		$('#edit_tweet_room3').fadeIn().css("width", "100%").css("height", "100%").css("left", "0%").css("top", "0%");
		$('#edit_tweet_room3 textarea').val(res.result.text);
		$('#edit_tweet_room3 span').text(`${res.result.text.length}/120`);
		$('#edit_tweet_room3 textarea').focus();
    }).fail(function(e){
    });
}

$.fn.menu = function( options ) {
	
	var defaults = {
		level : './',
		menuBtn : '#menu_btn',
		closeBtn : '.menu_close',
	};
	
	var settings = $.extend( {}, defaults, options );
	
	var _this = this;
	var _menuBtn = $(settings.menuBtn);
	var _closeBtn = $(settings.closeBtn);
	
	var events = {
		moveToSpot : function() {}
	}

	_menuBtn.on('click', function() {
		$("#menu").toggleClass('show');
		hide_menu_btn_tooltip();
	});
	
	_closeBtn.on('click', function() {
		$("#menu").toggleClass('show');
	});
	
	$('#help_op .overlay').on('click', function(){
		$('#help_op').fadeOut();
	});

	$("#button_reload").on('click', function() {
		location.reload();
	});

	$('.help_modal .overlay').on('click', function(){
		if($(this).parent().attr('id') == 'dropped_modal') {
			return;
		}
		$(this).parent().fadeOut();
		$(`#room5-news-show iframe`).attr('src', '');
		if($(this).parent().attr('id') == 'questionnaire_modal') {
			// 问卷关闭后，显示直播提醒
			$("#youtube_live_modal").fadeIn();
		}
	});

	$('#map_overview_modal .overlay').on('click', function(){
		$('#map_overview_modal').fadeOut();
	});

	$('#grandma_talk .overlay').on('click', function(){
		
		$('#grandma_talk').fadeOut();
	});
	
	$('#avatar .overlay').on('click', function(){
		//$('#avatar').fadeOut();
	});
	

	$('#new_recommend_btn, #create_or_edit_recommend_btn').on('click', function() {
		$.ajax({
			type	: "post",
			url		: '/my_recommend',
			data	: {
			}
		}).done(function(res) {
			//console.log(res.result);
			my_recommend_id = res.result._id;
			$('#edit_recommend_room8 input').val(_userName );
			$('#edit_recommend_room8 textarea.line1').val(res.result.text1);
			$('#edit_recommend_room8 textarea.line2').val(res.result.text2);
			$('#edit_recommend_room8 textarea.line3').val(res.result.text3);
            $('#edit_recommend_room8 textarea.line4').val(res.result.content);
			$(`#choose_line1_btn`).html(`<picture style="width: 100%;">
				<source srcset="/static/scenes/main/files/categories/icon_cat_${categories_names[res.result.category1]}.webp" type="image/webp">
				<img src="/static/scenes/main/files/categories/icon_cat_${categories_names[res.result.category1]}.png" alt="BL80_category_palette" style="width: 100%;" loading="lazy">
			</picture>`);
			$(`#choose_line2_btn`).html(`<picture style="width: 100%;">
				<source srcset="/static/scenes/main/files/categories/icon_cat_${categories_names[res.result.category2]}.webp" type="image/webp">
				<img src="/static/scenes/main/files/categories/icon_cat_${categories_names[res.result.category1]}.png" alt="BL80_category_palette" style="width: 100%;" loading="lazy">
			</picture>`);
			$(`#choose_line3_btn`).html(`<picture style="width: 100%;">
				<source srcset="/static/scenes/main/files/categories/icon_cat_${categories_names[res.result.category3]}.webp" type="image/webp">
				<img src="/static/scenes/main/files/categories/icon_cat_${categories_names[res.result.category1]}.png" alt="BL80_category_palette" style="width: 100%;" loading="lazy">
			</picture>`);
			$("#edit_recommend_room8").show();
		}).fail(function(e){
		});
	});

	let cur_recommend_page = 0, total_recommends = 0;
	const recommend_page_limit = 1;
	const get_recommend = function(page, func) {
		$.ajax({
            type	: "post",
            url		: '/get_recommends',
            data	: {
				'page'		: page,
            	'limit' 		: recommend_page_limit,
            }
        }).done(function(res) {
			func(res);
        }).fail(function(e){
        });
	};

	let cur_my_recommend_page = 0, total_my_recommends = 0;
	const get_my_recommends = function(page, func) {
		$.ajax({
            type	: "post",
            url		: '/get_my_recommends',
            data	: {
				'page'		: page,
            	'limit' 		: recommend_page_limit,
            }
        }).done(function(res) {
			func(res);
        }).fail(function(e){
        });
	};

	let cur_my_recommend_id = "";
	const fill_my_reocmmend_form = function(res) {
		cur_my_recommend_id = res.result[0]['_id'];
		$('#show_my_recommends_room8 textarea.line1').val(res.result[0]['text1']);
		$('#show_my_recommends_room8 textarea.line2').val(res.result[0]['text2']);
		$('#show_my_recommends_room8 textarea.line3').val(res.result[0]['text3']);
		$('#show_my_recommends_room8 textarea.line4').val(res.result[0]['content']);
		$('#show_my_recommends_room8 input').val(res.result[0]['name']);
		$('#my_recommend_date').text(res.result[0]['created_at']);
		$('#my_line1_btn').html(`<picture style="width: 100%;">
			<source srcset="/static/scenes/main/files/categories/icon_cat_${categories_names[parseInt(res.result[0]['category1'])]}.webp" type="image/webp">
			<img src="/static/scenes/main/files/categories/icon_cat_${categories_names[parseInt(res.result[0]['category1'])]}.png" alt="BL80_category_palette" style="width: 100%;" loading="lazy">
		</picture>`);
		$('#my_line2_btn').html(`<picture style="width: 100%;">
			<source srcset="/static/scenes/main/files/categories/icon_cat_${categories_names[parseInt(res.result[0]['category2'])]}.webp" type="image/webp">
			<img src="/static/scenes/main/files/categories/icon_cat_${categories_names[parseInt(res.result[0]['category2'])]}.png" alt="BL80_category_palette" style="width: 100%;" loading="lazy">
		</picture>`);
		$('#my_line3_btn').html(`<picture style="width: 100%;">
			<source srcset="/static/scenes/main/files/categories/icon_cat_${categories_names[parseInt(res.result[0]['category3'])]}.webp" type="image/webp">
			<img src="/static/scenes/main/files/categories/icon_cat_${categories_names[parseInt(res.result[0]['category3'])]}.png" alt="BL80_category_palette" style="width: 100%;" loading="lazy">
		</picture>`);
	}

	$('#show_my_recommends_btn, #show_my_recommends_btn2').on('click', function(){
		cur_my_recommend_page = 0;
		get_my_recommends(cur_my_recommend_page, function(res) {
			$('#show_my_recommends_room8').fadeIn();
			total_my_recommends = res['total'];
			if(total_my_recommends > 0) {
				fill_my_reocmmend_form(res);
			} else {
				$('#show_my_recommends_room8 textarea').val('');
				$('#show_my_recommends_room8 input').val('');
			}
		});
	});

	$('#BL80_view_my_button_left').on('click', function(){
		if(cur_my_recommend_page == 0)
			return;
		cur_my_recommend_page -= 1;
		get_my_recommends(cur_my_recommend_page, function(res) {
			fill_my_reocmmend_form(res);
		});
	});
	$('#BL80_view_my_button_right').on('click', function(){
		if(cur_my_recommend_page >= total_my_recommends - 1)
			return;
		cur_my_recommend_page += 1;
		get_my_recommends(cur_my_recommend_page, function(res) {
			fill_my_reocmmend_form(res);
		});
	});

	let cur_recommend_id = "";
	let likes = [false, false, false];
	let category1 = 1, category2=2, category3=3;
	const fill_reocmmend_form = function(res) {
		cur_recommend_id = res.result[0]['_id'];
		category1 = parseInt(res.result[0]['category1']);
		category2 = parseInt(res.result[0]['category2']);
		category3 = parseInt(res.result[0]['category3']);
		$('#show_recommends_room8 textarea.line1').val(res.result[0]['text1']);
		$('#show_recommends_room8 textarea.line2').val(res.result[0]['text2']);
		$('#show_recommends_room8 textarea.line3').val(res.result[0]['text3']);
		$('#show_recommends_room8 textarea.line4').val(res.result[0]['content']);
		$('#show_recommends_room8 input').val(res.result[0]['name']);
		$('#line1_btn').html(`<picture style="width: 100%;">
			<source srcset="/static/scenes/main/files/categories/icon_cat_${categories_names[parseInt(res.result[0]['category1'])]}.webp" type="image/webp">
			<img src="/static/scenes/main/files/categories/icon_cat_${categories_names[parseInt(res.result[0]['category1'])]}.png" alt="BL80_category_palette" style="width: 100%;" loading="lazy">
		</picture>`);
		$('#line2_btn').html(`<picture style="width: 100%;">
			<source srcset="/static/scenes/main/files/categories/icon_cat_${categories_names[parseInt(res.result[0]['category2'])]}.webp" type="image/webp">
			<img src="/static/scenes/main/files/categories/icon_cat_${categories_names[parseInt(res.result[0]['category2'])]}.png" alt="BL80_category_palette" style="width: 100%;" loading="lazy">
		</picture>`);
		$('#line3_btn').html(`<picture style="width: 100%;">
			<source srcset="/static/scenes/main/files/categories/icon_cat_${categories_names[parseInt(res.result[0]['category3'])]}.webp" type="image/webp">
			<img src="/static/scenes/main/files/categories/icon_cat_${categories_names[parseInt(res.result[0]['category3'])]}.png" alt="BL80_category_palette" style="width: 100%;" loading="lazy">
		</picture>`);
		//$('#show_recommends_room8 input').val(res.result[0]['author']);
		$("#like_line1").find('picture source').remove();
		likes[0] = res.result[0]['liked1'];
		if(likes[0]) {
			$("#like_line1").find("img").attr("src", _level + "files/heart_red.webp");
		} else {
			$("#like_line1").find("img").attr("src", _level + "files/heart_grey.webp");
		}

		$("#like_line2").find('picture source').remove();
		likes[1] = res.result[0]['liked2'];
		if(likes[1]) {
			$("#like_line2").find("img").attr("src", _level + "files/heart_red.webp");
		} else {
			$("#like_line2").find("img").attr("src", _level + "files/heart_grey.webp");
		}

		$("#like_line3").find('picture source').remove();
		likes[2] = res.result[0]['liked3'];
		if(likes[2]) {
			$("#like_line3").find("img").attr("src", _level + "files/heart_red.webp");
		} else {
			$("#like_line3").find("img").attr("src", _level + "files/heart_grey.webp");
		}
	}

	$('#show_recommends_btn, #show_recommends_btn2').on('click', function(){
		cur_recommend_page = 0;
		get_recommend(cur_recommend_page, function(res) {
			$('#show_recommends_room8').fadeIn();
			total_recommends = res['total'];
			$('#recommend_num').text(total_recommends);
			if(total_recommends > 0) {
				fill_reocmmend_form(res);
				$('#cur_recommend_idx').text(cur_recommend_page+1);
			} else {
				$('#show_recommends_room8 textarea').val('');
				$('#show_recommends_room8 input').val('');
				$('#cur_recommend_idx').text(0);
			}
		});
	});

	$('#BL80_view_button_left').on('click', function(){
		if(cur_recommend_page == 0)
			return;
		cur_recommend_page -= 1;
		get_recommend(cur_recommend_page, function(res) {
			$('#recommend_num').text(total_recommends);
			fill_reocmmend_form(res);
			$('#cur_recommend_idx').text(cur_recommend_page+1);
		});
	});
	$('#BL80_view_button_right').on('click', function(){
		if(cur_recommend_page >= total_recommends - 1)
			return;
		cur_recommend_page += 1;
		get_recommend(cur_recommend_page, function(res) {
			$('#recommend_num').text(total_recommends);
			fill_reocmmend_form(res);
			$('#cur_recommend_idx').text(cur_recommend_page+1);
		});
	});

	$(".like_line_btns").on('click', function(){
		const categories = [category1, category2, category3];
		const that = $(this);
		const cat_idx = parseInt($(this).data('target'));
		if(!likes[cat_idx]) {
			$.ajax({
				type	: "post",
				url		: '/like_recommendation',
				data	: {
					'recommendation_id': cur_recommend_id,
					'category': parseInt($(this).data('target')),
				}
			}).done(function(res) {
				likes[cat_idx] = true;
				that.find('picture source').remove();
				that.find("img").attr("src", _level + "files/heart_red.webp");
			}).fail(function(e){
			});
		}
		else {
			$.ajax({
				type	: "post",
				url		: '/unlike_recommendation',
				data	: {
					'recommendation_id': cur_recommend_id,
					'category': parseInt($(this).data('target')),
				}
			}).done(function(res) {
				likes[cat_idx] = false;
				that.find('picture source').remove();
				that.find("img").attr("src", _level + "files/heart_grey.webp");
			}).fail(function(e){
			});
		

		}

	});

	$('#edit_tweet_btn, #edit_tweet_btn2').on('click', show_edit_tweet);
	$('#button_02_taberu').on('click', function(){
		if($('#edit_tweet_room3 textarea').val().length <= 0)
			return;
		$("#room3_anim1").hide();
		const timestamp= new Date().getTime().toString();
		$("#room3_anim_taberu").show();
		setTimeout(function() {
			$("#room3_anim_taberu").hide();
			$("#room3_anim1").show();
		}, 11*1000);
		$.ajax({
            type	: "post",
            url		: '/delete_my_tweet',
            data	: {
            }
        }).done(function(res) {
			$('#edit_tweet_room3').animate({left: "36%", top: "36.5%", width: "0%", height: "0%"}, 1000).fadeOut();
			$('#edit_tweet_room3 textarea').val('');
		});
	});
	$('#button_01_haru').on('click', function(){
		if($('#edit_tweet_room3 textarea').val().length <= 0)
			return;
		$('#edit_tweet_room3').animate({left: "56%", top: "26.5%", width: "0%", height: "0%"}, 500).fadeOut();
		$("#room3_anim_haru").fadeIn().delay(1000).fadeOut();
		$("#room3_anim_haru_static").fadeOut().delay(1000).fadeIn();
		$("#edit_tweet_btn").fadeOut().delay(1000).fadeIn();
		const d = {
            type	: "post",
            url		: '/update_tweet',
            data	: {
				'_id': my_tweet_id,
				'author'		: $('#edit_tweet_room3 input').val(),
            	'text' 		: $('#edit_tweet_room3 textarea').val(),
            }
        };
		console.log(d);
		$.ajax(d).done(function(res) {
			$('#edit_tweet_room3 textarea').val('');
        }).fail(function(e){
        });
	});
	let cur_tweet_page = 0, total_tweets = 0;
	let cur_tweet_id = "";
	let tweet_liked = false;
	const tweet_page_limit = 1;
	const get_tweet = function(page, func) {
		//console.log('page', page);
		$.ajax({
            type	: "post",
            url		: '/get_tweets',
            data	: {
				'page'		: page,
            	'limit' 		: tweet_page_limit,
            }
        }).done(function(res) {
			func(res);
			cur_tweet_id = res.result[0]['_id'];
        }).fail(function(e){
        });
	};
	$('.show_tweet_btns').on('click', function(){
		cur_tweet_page = 0;
		get_tweet(cur_tweet_page, function(res) {
			//console.log(res);
			$('#show_tweet_room3').fadeIn();
			total_tweets = res['total'];
			$('#tweet_num').text(total_tweets);
			if(total_tweets > 0) {
				$('#show_tweet_room3 textarea').val(res.result[0]['text']);
				$('#show_tweet_room3 input').val(res.result[0]['author']);
				$('#cur_tweet_idx').text(cur_tweet_page+1);
				tweet_liked = res.result[0]['liked'];
				if(res.result[0]['liked']) {
					$("#like_tweet").find('picture source').remove();
					$("#like_tweet").find("img").attr("src", _level + "files/heart_red.webp");
				} else {
					$("#like_tweet").find('picture source').remove();
					$("#like_tweet").find("img").attr("src", _level + "files/heart_grey.webp");
				}
			} else {
				$('#show_tweet_room3 textarea').val('');
				$('#show_tweet_room3 input').val('');
				$('#cur_tweet_idx').text(0);
			}
		});
	});
	$('#BL30_view_button_left').on('click', function(){
		if(cur_tweet_page == 0)
			return;
		cur_tweet_page -= 1;
		get_tweet(cur_tweet_page, function(res) {
			//console.log(res);
			$('#tweet_num').text(total_tweets);
			$('#show_tweet_room3 textarea').val(res.result[0]['text']);
			$('#show_tweet_room3 input').val(res.result[0]['author']);
			$('#cur_tweet_idx').text(cur_tweet_page+1);
			tweet_liked = res.result[0]['liked'];
			if(res.result[0]['liked']) {
				$("#like_tweet").find('picture source').remove();
				$("#like_tweet").find("img").attr("src", _level + "files/heart_red.webp");
			} else {
				$("#like_tweet").find('picture source').remove();
				$("#like_tweet").find("img").attr("src", _level + "files/heart_grey.webp");
			}
		});
	});
	$('#BL30_view_button_right').on('click', function(){
		if(cur_tweet_page >= total_tweets - 1)
			return;
		cur_tweet_page += 1;
		get_tweet(cur_tweet_page, function(res) {
			//console.log(res);
			$('#tweet_num').text(total_tweets);
			$('#show_tweet_room3 textarea').val(res.result[0]['text']);
			$('#show_tweet_room3 input').val(res.result[0]['author']);
			$('#cur_tweet_idx').text(cur_tweet_page+1);
			tweet_liked = res.result[0]['liked'];
			if(res.result[0]['liked']) {
				$("#like_tweet").find('picture source').remove();
				$("#like_tweet").find("img").attr("src", _level + "files/heart_red.webp");
			} else {
				$("#like_tweet").find('picture source').remove();
				$("#like_tweet").find("img").attr("src", _level + "files/heart_grey.webp");
			}
		});
	});
	$("#like_tweet").on('click', function(){
		const that = $(this);
		if(!tweet_liked) {
			$.ajax({
				type	: "post",
				url		: '/like_tweet',
				data	: {
					'tweet_id': cur_tweet_id,
				}
			}).done(function(res) {
				tweet_liked = true;
				that.find('picture source').remove();
				that.find("img").attr("src", _level + "files/heart_red.webp");
			}).fail(function(e){
			});
		}
		else {
			$.ajax({
				type	: "post",
				url		: '/unlike_tweet',
				data	: {
					'tweet_id': cur_tweet_id,
				}
			}).done(function(res) {
				tweet_liked = false;
				that.find('picture source').remove();
				that.find("img").attr("src", _level + "files/heart_grey.webp");
			}).fail(function(e){
			});
		

		}

	});

	$('#room1_guide_btn, #room1_guide_btn2').on('click', function(){
		$('#room1_guide_modal').fadeIn();
	});
	$('#room1_readme_btn, #room1_readme_btn2').on('click', function(){
		$('#room1_readme_modal').fadeIn();
	});
	$('#room1_snack_btn, #room1_snack_btn2').on('click', function(){
		$('#room1_stories_modal').fadeIn();
	});
	$("#room1_show_story .myclose").on('click', function(){
		$('#room1_show_story').hide();
	});

	$('#show_games_btn').on('click', function(){
		$('#game_menu_room6').fadeIn();
	});
	let cur_game_id = 0;
	const games = ["heading", "kanpo", "kushakusha", "kurihiroi"];
	const game_ports = [8080, 8081, 8083, 8082];
	const play_game = function(game_idx) {
		console.log('Start game', game_idx, games[game_idx]);
		$.ajax({
            type	: "post",
            url		: '/add_action_log',
            data	: {
				'room': 60,
				'target'		: game_idx,
            }
        }).done(function(res) {
			$('#game_play_room6').fadeIn();
			const url = `http://43.207.221.49:${game_ports[game_idx]}/?client_id=${_userId}`;
			$('#game_play_room6 iframe').attr("src", url);
        }).fail(function(e){
			$('#game_play_room6').fadeIn();
			const url = `http://43.207.221.49:${game_ports[game_idx]}/?client_id=${_userId}`;
			$('#game_play_room6 iframe').attr("src", url);
        });

	}
	$('#room6_cur_game_spot, #cur_game_btn').on('click', function(){
		play_game(cur_game_id);
	});

	$('.room6_game_start_btns').on('click', function(){
		const game_idx = parseInt($(this).data('target'));
		play_game(game_idx);
	});

	$('#prev_game_btn').on('click', function(){
		if(cur_game_id == 0)
			cur_game_id = games.length - 1;
		else
			cur_game_id -= 1;
		$('#cur_game_btn picture source').remove();
		$("#cur_game_btn img").attr("src", _level + `files/games/${games[cur_game_id]}.webp`);
	});

	$('#next_game_btn').on('click', function(){
		if(cur_game_id == games.length - 1)
			cur_game_id = 0;
		else
			cur_game_id += 1;
		$('#cur_game_btn picture source').remove();
		$("#cur_game_btn img").attr("src", _level + `files/games/${games[cur_game_id]}.webp`);
	});

	setInterval(function() {
		if($("#room6_bg").is(":visible")) {
			if(cur_game_id == games.length - 1)
				cur_game_id = 0;
			else
				cur_game_id += 1;
			$( "#cur_game_btn" ).slideToggle('slow', function() {
				$('#cur_game_btn picture source').remove();
				$("#cur_game_btn img").attr("src", _level + `files/games/${games[cur_game_id]}.webp`);
				$( "#cur_game_btn" ).slideToggle('slow');
			});
		}
	}, 5000);

	$('#game1_btn').on('click', function(){
		$('#game_menu_room6').hide();
		play_game(0);
	});
	$('#game2_btn').on('click', function(){
		$('#game_menu_room6').hide();
		play_game(1);
	});
	$('#game3_btn').on('click', function(){
		$('#game_menu_room6').hide();
		$('#game_play_room6').fadeIn();
		play_game(2);
	});
	$('#game4_btn').on('click', function(){
		$('#game_menu_room6').hide();
		$('#game_play_room6').fadeIn();
		play_game(3);
	});

	let cur_story_idx = 1;
	let cur_story_img_idx = 1;
	let max_story_img_idx = 6;
	const set_story_img = function(cb=null) {
		$("#story_img_idx").text(cur_story_img_idx);
		$("#story_img_num").text(max_story_img_idx);
		if(cur_story_img_idx == 1) {
			$("#room1_show_story picture.story source").attr("srcset", _level + "files/BL01_story_" + String(cur_story_idx).padStart(2, '0') + "_cover" +".webp");
			$("#room1_show_story picture.story img").attr("src", _level + "files/BL01_story_" + String(cur_story_idx).padStart(2, '0') + "_cover" +".png");
			$(".story_end_img").hide();
		} else if(cur_story_img_idx == max_story_img_idx){
			$("#room1_show_story picture.story source").attr("srcset", _level + "files/BL01_story_" + String(cur_story_idx).padStart(2, '0') + "_cover" +".webp");
			$("#room1_show_story picture.story img").attr("src", _level + "files/BL01_story_" + String(cur_story_idx).padStart(2, '0') + "_cover" +".png");
			$(".story_end_img").show();
		} else {
			$("#room1_show_story picture.story source").attr("srcset", _level + "files/BL01_story_" + String(cur_story_idx).padStart(2, '0') + "_" + String(cur_story_img_idx-1).padStart(3, '0') +".webp");
			$("#room1_show_story picture.story img").attr("src", _level + "files/BL01_story_" + String(cur_story_idx).padStart(2, '0') + "_" + String(cur_story_img_idx-1).padStart(3, '0') +".png");
			$(".story_end_img").hide();
		}
		$.ajax({
            type	: "post",
            url		: '/get_story_like',
            data	: {
				story: `${cur_story_idx}_${cur_story_img_idx}`,
			}
        }).done(function(res) {
			story_like = res.result;
			if(story_like) {
				$("#like_story").find('picture source').remove();
				$("#like_story").find("img").attr("src", _level + "files/heart_red.webp");
			} else {
				$("#like_story").find('picture source').remove();
				$("#like_story").find("img").attr("src", _level + "files/heart_grey.webp");
			}
			if(cb != null)
				cb();
        }).fail(function(e){
        });

	};
	const story_img_nums = [5, 7, 3, 2, 6, 3, 15, 5, 3, 3, 4, 4, 4, 6, 7, 5, 5];
	let story_like = false;
	$('.story_btn').on('click', function() {
		cur_story_idx = parseInt($(this).data("idx"));
		cur_story_img_idx = 1;
		max_story_img_idx = story_img_nums[cur_story_idx-1] + 2;// plus a cover and a end page
		set_story_img(function() {
			$('#room1_show_story').fadeIn();
		});
	});
	$('#BL01_story_button_left').on('click', function() {
		if(cur_story_img_idx <= 1)
			return;
		cur_story_img_idx -= 1;
		set_story_img();
	});
	$('#BL01_story_button_right').on('click', function() {
		if(cur_story_img_idx >= max_story_img_idx)
			return;
		cur_story_img_idx += 1;
		set_story_img();
	});
	$("#like_story").on('click', function(){
		const that = $(this);
		if(!story_like) {
			$.ajax({
				type	: "post",
				url		: '/like_story',
				data	: {
					'story':`${cur_story_idx}_${cur_story_img_idx}`,
				}
			}).done(function(res) {
				story_like = true;
				that.find('picture source').remove();
				that.find("img").attr("src", _level + "files/heart_red.webp");
			}).fail(function(e){
			});
		}
		else {
			$.ajax({
				type	: "post",
				url		: '/unlike_story',
				data	: {
					'story': `${cur_story_idx}_${cur_story_img_idx}`,
				}
			}).done(function(res) {
				story_like = false;
				that.find('picture source').remove();
				that.find("img").attr("src", _level + "files/heart_grey.webp");
			}).fail(function(e){
			});
		

		}

	});
	return {
		events : events
	}
}

function windowsBreakPoint(breakPC, breakSP, breakPoint) {
	
	var windowWidth;
	var checkBreakPC = 0;
	var checkBreakSP = 0;

	var checkPixels = function () {
		windowWidth = $(window).width();

		if(windowWidth <= breakPoint) {
			//sp
			checkBreakPC = 0;
			if(checkBreakSP == 0) { breakSP(windowWidth); checkBreakSP = 1; }
		} else {
			// pc
			checkBreakSP = 0;
			if(checkBreakPC == 0) { breakPC(windowWidth); checkBreakPC = 1; }
		}
	}

	checkPixels();
	
	$(window).resize(function() { checkPixels(); });

}



$(document).ready(function() {
	$('#edit_tweet_room3 textarea').bind('input propertychange', function() {
		$('#edit_tweet_room3 span').text(`${this.value.length}/120`);
	});
	$('#room3_help_spot').on('click', function(){
		$('#help_room3').show();
	});
	$('#room5_help_spot').on('click', function(){
		$('#help_room5').show();
	});
	$('#room6_help_spot').on('click', function(){
		$('#help_room6').show();
	});
	$('#room8_help_spot').on('click', function(){
		$('#help_room8').show();
	});

	$('#room2_chat_btn').on('click', function(){
		$('#room2_chat_modal').show();
	});

	$('#room2_sns_btn').on('click', function(){
		$('#room2_sns_modal').show();
	});

	let goto_links = [
		"https://yorisoi-chat.jp/index_kakurega.html",
		"https://lifelink.or.jp/inochisos/flow_kakurega.html",
		"https://lifelink.or.jp/inochisos/index_kakurega.html",
		"https://yorisoi-chat.jp/index_kakurega.html"
	];
	let goto_link_idx = 0;
	$('#room2_sns_link1').on('click', function(){
		$('#room2_confirm_modal picture source').remove();
		$("#room2_confirm_modal img").attr("src", _level + "files/BL20_message_exit_sns.webp");
		$('#room2_confirm_modal').show();
		goto_link_idx = 0;
	});
	$('#chat_bottom_p a').on('click', function(){
		$('#room2_confirm_modal picture source').remove();
		$("#room2_confirm_modal img").attr("src", _level + "files/BL20_message_exit_sns.webp");
		$('#room2_confirm_modal').show();
		goto_link_idx = 3;
	});
	$("#chat_agreement").scroll(function () {
     //alert("AI O CARALHO")
		if (Math.abs($("#chat_agreement")[0].scrollHeight - $("#chat_agreement")[0].clientHeight - $("#chat_agreement").scrollTop()) <= 1) {
			$('#chk_termos').removeAttr('disabled');
			$('#chk_termos_div label').css('color', 'black');
			$('#chk_termos_div label').css('font-weight', 'bold');
		} else {
			$('#chk_termos').attr('disabled', 'disabled');
			$('#chk_termos_div label').css('color', 'grey');
			$('#chk_termos_div label').css('font-weight', 'lighter');
		}
	});
	let room25_help_link1_enabled = false;
	$("#chk_termos").change(function() {
		if(this.checked) {
			$("#room25_help_link1").removeClass("bubble-round-disabled");
			$("#room25_help_link1").addClass("bubble-round");
			room25_help_link1_enabled = true;
		} else {
			$("#room25_help_link1").removeClass("bubble-round");
			$("#room25_help_link1").addClass("bubble-round-disabled");
			room25_help_link1_enabled = false;
		}
	});


	$('#room2_sns_link2').on('click', function(){
		$('#room2_confirm_modal picture source').remove();
		$("#room2_confirm_modal img").attr("src", _level + "files/BL20_message_exit_mail.webp");
		$('#room2_confirm_modal').show();
		goto_link_idx = 1;
	});
	$('#room2_tel_link1').on('click', function(){
		$('#room2_confirm_modal picture source').remove();
		$("#room2_confirm_modal img").attr("src", _level + "files/BL20_message_exit_tel.webp");
		$('#room2_confirm_modal').show();
		goto_link_idx = 2;
	});
	$('#room2_tel_link2').on('click', function(){
		$('#room2_confirm_modal picture source').remove();
		$("#room2_confirm_modal img").attr("src", _level + "files/BL20_message_exit_mail.webp");
		$('#room2_confirm_modal').show();
		goto_link_idx = 1;
	});
	$('#room2_tel_link3').on('click', function(){
		$('#room2_tel_modal').fadeOut();
	});
	$('#room2_sns_link3').on('click', function(){
		$('#room2_sns_modal').fadeOut();
	});
	$('#room2_yes').on('click', function(){
		$('#room2_confirm_modal').hide();
		$.ajax({
            type	: "post",
            url		: '/add_action_log',
            data	: {
				'room': 20,
				'target'		: goto_link_idx,
            }
        }).done(function(res) {
			window.location.href = goto_links[goto_link_idx];
        }).fail(function(e){
			window.location.href = goto_links[goto_link_idx];
        });
	});
	$('#room2_no').on('click', function(){
		$('#room2_confirm_modal').hide();
	});

	$('#room2_call_btn').on('click', function(){
		$('#room2_tel_modal').show();
	});

	$('.room4-pop-btns').on('click', function(){
		$(`#room4-pop${$(this).data('target')}`).fadeIn();
	});

	$('.room4-pop-ok-btns').on('click', function(){
		$(`#room4-article${$(this).data('target')} iframe`).attr('src', `/static/scenes/main/files/room4_articles/${$(this).data('target')}.html`);
		$(`#room4-article${$(this).data('target')}`).fadeIn();
		$.ajax({
            type	: "post",
            url		: '/add_action_log',
            data	: {
				'room': 40,
				'target'		: $(this).data('target'),
            }
        })
	});

	$('.room4-profile-btns').on('click', function(){
		$(`#room4-profile${$(this).data('target')}`).fadeIn();
	});
	$('.profile_close').on('click', function(){
		$(`#room4-profile${$(this).data('target')}`).hide();
		$(`#room4-article${$(this).data('target')}`).show();
	});

	$('#show_news_list_btn2, #show_news_list_btn').on('click', function(){
		$(`#room5-news`).fadeIn();
	});

	let cur_news_page = 0, total_news = 0;
	let cur_news_id = "";
	let news_liked = false;
	const news_page_limit = 1;
	const get_news = function(page, func) {
		$.ajax({
            type	: "post",
            url		: '/get_news',
            data	: {
				'page'		: page,
            	'limit' 		: news_page_limit,
            }
        }).done(function(res) {
			func(res);
			cur_news_id = res.result[0]['_id'];
		}).fail(function(e){
		});
	};

	const query_news = function(_id, func) {
		$.ajax({
            type	: "post",
            url		: '/query_news',
            data	: {
				'_id'		: _id,
            }
        }).done(function(res) {
			//console.log(res);
			func(res);
			cur_news_id = res.result[0]['_id'];
			cur_news_page = res.page;
		}).fail(function(e){
		});
	};

	$('#BL50_view_button_left').on('click', function(){
		if(cur_news_page == 0)
			return;
		cur_news_page -= 1;
		get_news(cur_news_page, function(res) {
			$('#news_num').text(total_news);
			$('#news_title').html(res.result[0]['title']);
			news_liked = res.result[0]['liked'];
			$(`#room5-news-show iframe`).attr('src', res.result[0]['url']);
			$('#cur_news_idx').text(cur_news_page+1);
			if(news_liked) {
				$("#like_news").find('picture source').remove();
				$("#like_news").find("img").attr("src", _level + "files/heart_red.webp");
			} else {
				$("#like_news").find('picture source').remove();
				$("#like_news").find("img").attr("src", _level + "files/heart_grey.webp");
			}
		});
	});
	$('#BL50_view_button_right').on('click', function(){
		if(cur_news_page >= total_news - 1)
			return;
		cur_news_page += 1;
		get_news(cur_news_page, function(res) {
			$('#news_num').text(total_news);
			$('#news_title').html(res.result[0]['title']);
			news_liked = res.result[0]['liked'];
			$(`#room5-news-show iframe`).attr('src', res.result[0]['url']);
			$('#cur_news_idx').text(cur_news_page+1);
			if(news_liked) {
				$("#like_news").find('picture source').remove();
				$("#like_news").find("img").attr("src", _level + "files/heart_red.webp");
			} else {
				$("#like_news").find('picture source').remove();
				$("#like_news").find("img").attr("src", _level + "files/heart_grey.webp");
			}
		});
	});

	$('#room5-news .news_title').on('click', function(){
		query_news($(this).data('target'), function(res) {
			console.log(res);
			total_news = res['total'];
			cur_news_page = res.page;
			news_liked = res.result[0]['liked'];
			$('#news_num').text(total_news);
			$('#news_title').html(res.result[0]['title']);
			$('#cur_news_idx').text(cur_news_page+1);
			$(`#room5-news-show iframe`).attr('src', res.result[0]['url']);
			$(`#room5-news-show`).fadeIn();
			if(news_liked) {
				$("#like_news").find('picture source').remove();
				$("#like_news").find("img").attr("src", _level + "files/heart_red.webp");
			} else {
				$("#like_news").find('picture source').remove();
				$("#like_news").find("img").attr("src", _level + "files/heart_grey.webp");
			}
		});
	});

	$('#first_news_btn, #first_news_btn_bubble').on('click', function(){
		query_news($(this).data('target'), function(res) {
			console.log(res);
			total_news = res['total'];
			cur_news_page = res.page;
			news_liked = res.result[0]['liked'];
			$('#news_num').text(total_news);
			$('#news_title').html(res.result[0]['title']);
			$('#cur_news_idx').text(cur_news_page+1);
			$(`#room5-news-show iframe`).attr('src', res.result[0]['url']);

			$(`#room5-news-show`).fadeIn();
			if(news_liked) {
				$("#like_news").find('picture source').remove();
				$("#like_news").find("img").attr("src", _level + "files/heart_red.webp");
			} else {
				$("#like_news").find('picture source').remove();
				$("#like_news").find("img").attr("src", _level + "files/heart_grey.webp");
			}
		});
	});
	$("#like_news").on('click', function(){
		const that = $(this);
		if(!news_liked) {
			$.ajax({
				type	: "post",
				url		: '/like_news',
				data	: {
					'news_id': cur_news_id,
				}
			}).done(function(res) {
				tweet_liked = true;
				that.find('picture source').remove();
				that.find("img").attr("src", _level + "files/heart_red.webp");
			}).fail(function(e){
			});
		}
		else {
			$.ajax({
				type	: "post",
				url		: '/unlike_news',
				data	: {
					'news_id': cur_news_id,
				}
			}).done(function(res) {
				tweet_liked = false;
				that.find('picture source').remove();
				that.find("img").attr("src", _level + "files/heart_grey.webp");
			}).fail(function(e){
			});
		

		}

	});

	$('.room7-pop-btns').on('click', function(){
		$(`#room7-pop${$(this).data('target')}`).fadeIn();
	});

	$('.room7-pop-ok-btns').on('click', function(){
		$.ajax({
            type	: "post",
            url		: '/add_action_log',
            data	: {
				'room': 70,
				'target'		: $(this).data('target'),
            }
        })
		$(`#room7-article${$(this).data('target')}`).fadeIn();
	});

	$('.room7-profile-btns').on('click', function(){
		$(`#room7-profile${$(this).data('target')}`).fadeIn();
	});

	$(".npc a").on('click', function(){
		if($(this).parent().attr('id') == 'npc_room4_guide' || $(this).parent().attr('id') == 'npc_room7_guide')
			return;
		$(".npc-pop").hide();
		const that = $(this);
		const npc = $(this).parent(".npc");
		$.ajax({
            type	: "post",
            url		: '/npc_pic',
            data	: {
				npc: $(this).parent().attr('id').substring(3)
			}
        }).done(function(res) {
			$(`#${that.parent().attr('id')}-pop p`).html(res.result);
			$(`#${that.parent().attr('id')}-pop`).toggle();
        }).fail(function(e){
        });

	});
	$(".npc-pop").on('click', function(){
		$(this).toggle();
	});
	$("#npc_room4_guide").on('click', function(){
		$("#npc_room4-pop").toggle();
	});
	$("#npc_room7_guide").on('click', function(){
		$("#npc_room7-pop").toggle();
	});

	$('.room7-profile_close').on('click', function(){
		$(`#room7-profile${$(this).data('target')}`).hide();
		$(`#room7-article${$(this).data('target')}`).show();
	});

	$('.room7-subarticle-btn-1, .room7-subarticle-btn-2, .room7-subarticle-btn-3, .room7-subarticle-btn-4').on('click', function(){
		$(`#room7-subarticle-${$(this).data('target')}`).fadeIn();
	});

	$('.room7-subarticle_close').on('click', function(){
		$(`#room7-subarticle-${$(this).data('target')}`).hide();
	});

	$('#room1_chat_btn').on('click', function(){
		$.ajax({
            type	: "post",
            url		: '/grandma_talk',
            data	: {}
        }).done(function(res) {
			$('#room1-grandma-pop picture source').remove();
			$("#room1-grandma-pop img").attr("src", _level + "files/grandma_talks/" + res.result);
			$("#room1-grandma-pop").show();
        }).fail(function(e){
        });
	});

	$('#room25_chat_btn').on('click', function(){
		$("#room25_help_modal").show();
	});
	function choose(choices) {
	  var index = Math.floor(Math.random() * choices.length);
	  return choices[index];
	}
	const animates = ["tada1", "pulse", "rubberBand1", "swing1", "wobble1"];
	$('#story_grid .grid-item').each(function(){
		$(this).addClass(`animated ${choose(animates)} infinite`);
	});
	$('#room25_help_link1').on('click', function(){
		if(!room25_help_link1_enabled)
			return;
		$("#room25_help_modal").hide();
		$('#chat_history').empty();
		//var dt = new Date();
		var str_date = "";//dt.getMonth() + 1 + '/' + dt.getDate() + ' ' + dt.getHours().toString().padStart(2, '0') + ':' + dt.getMinutes().toString().padStart(2, '0');
		$("#chat_list").find("ul").append('<li class="left"><div class="pic"><img src="/static/scenes/main/files/BL25_anime_01.webp" width="90" /></div><div class="box"><p class="info" style="color:#000000">むかんけいロボ <span>' + str_date + '</span></p><p class="text">こんにちは。アタシは、むかんけいロボ。おしゃべりしましょう♪</p></div></li>');
		//$('#chat_list').find('ul').scrollTop($('#chat_list').find('ul')[0].scrollHeight);
		var scroll=$('#chat_list').find('ul');
		scroll.animate({scrollTop: 9999});
		$('#chat_list').fadeIn();
	});
	
	$('#room25_help_link2').on('click', function(){
		$("#room25_help_modal").hide();
	});

	$('.modal_overlay, .close').on('click', function(){
		closeModal();
	});
});

function closeModal() {
	$('.modal_overlay').fadeOut();
	$('#help, #grandma_talk, #help_op, .help_modal, #map_overview_modal, #room2_chat_modal, .help_modal').fadeOut();
	$(`#room5-news-show iframe`).attr('src', '');
}

function openModal(url, maxWidth = 1600) {
	
	$('#modal-login').hide();
	
	if(url && url !== undefined) {
	
		$('#modal').css({
			'max-width' : maxWidth+'px'
		});
		$('#modal, .modal_overlay').fadeIn();
		$('#modal iframe').attr('src', url);
		$('#modal iframe').show();
		
	}
}

$(function() {
	// disable mobile pinch zoom
	document.addEventListener('gesturestart', function(e) {
		e.preventDefault();
		// special hack to prevent zoom-to-tabs gesture in safari
		document.body.style.zoom = 0.99;
	});

	document.addEventListener('gesturechange', function(e) {
		e.preventDefault();
		// special hack to prevent zoom-to-tabs gesture in safari
		document.body.style.zoom = 0.99;
	});

	document.addEventListener('gestureend', function(e) {
		e.preventDefault();
		// special hack to prevent zoom-to-tabs gesture in safari
		document.body.style.zoom = 0.99;
	});

	// on load
	$(window).on("load", function(){
		$('#map').addClass('loaded');
		setTimeout(function(){
			$("#loader").fadeOut();
			$('#clouds p').addClass('close');
			}, 500);
	});

	$(document).ready(function() {
		$('body').show();
		// handles menu function / events
		const menu = $('#menu').menu();
		// handles vritual map functions
		const map = $('#map').virtualMap({ parentDiv : $('#map-wrapper') });

		// handles login functions
		const login = $(this).login();

		// handles character selection and nickname
		const avatar = $('#avatar').avatar(map);

		login.events.onLogin = function() {
			chat.init();
		}

		const return_to_tree = function(room_name, dir="up", zoom=2, fadeInDuration=0, cb=null) {
			$(".real-person").remove();
			all_cubes = tree_all_cubes;
			$('#user'+_userId).hide();
			$("#map").hide();
			_characterMoveInterval = treeMoveInterval;
			_roomName = "tree";
			localStorage.setItem("room_name2", _roomName);
			let scrollToUser = false;
			if(room_name != "entrance") {
				resize_map_to_tree_size();
				scrollToUser = true;
			}

			$("#map .person").css("width", "2.5%");
			_socket.emit('join_room', {room_name: "tree", pos: localStorage.getItem("tree_pos3"), character: _userCharacter, nickname: _userName} , function() {
				$("#zoom_in, #zoom_out").show();
				$(rooms[room_name]['blk']).hide();
				$(rooms[room_name]['bg']).hide();
				$("#tree_blocks").show();
				$(".tree-blk").show();
				$("#tree_map_objs").show();
				_aryZoomSpX = treeZoomPhoneX;
				_aryZoomSpY = treeZoomPhoneY;
				_aryZoomX = treeZoomX;
				_aryZoomY = treeZoomY;
				_mapRatio = treeMapRatio;
				_zoom = zoom;
				map.zoomMap();
				const f = function() {
					$(".room_links").show();
					$(".front_layer").show();
					const pos = localStorage.getItem("tree_pos3");
					let tmp = pos.split('-');
					let axis = all_cubes[pos].axis;
					cur_pos_js = [parseInt(tmp[0]), parseInt(tmp[1])];
					cur_pos = pos;
					map.moveCharacter({
						type		: 'change_pos',
						userId 		: _userId,
						xAxis 		: axis[0], 
						yAxis 		: axis[1], 
						zIndex		: cur_pos_js[0]+cur_pos_js[1],
						avatar		: _userCharacter,
						direction 	: dir
					}, scrollToUser, false);
					if(room_name != "entrance")
						$('#user'+_userId).show();
					if(cb != null)
						cb();
					if(target_room_after_exit_room != null) {
						$(".block" + rooms[target_room_after_exit_room].enter_blks[0]).trigger('click');
						target_room_after_exit_room = null;
					}
				}
				$("#map").fadeIn(fadeInDuration, f);
			});
		}
		map.events.moveDone = function(positionId) {
			//console.log('moveDone', positionId, _roomName);
			//map.scrollToPosition();
			if(_roomName == "entrance") {
			}
			if(_roomName == "tree") {
				let found = rooms["room1"].enter_blks.find((element) => element == positionId);
				if(found != undefined) {
					map.save_my_pos(rooms["room1"].tree_back_pos);
					avatar.enter_room("room1");
				}
				found = rooms["room2"].enter_blks.find((element) => element == positionId);
				if(found != undefined) {
					map.save_my_pos(rooms["room2"].tree_back_pos);
					avatar.enter_room("room2");
				}
				found = rooms["room25"].enter_blks.find((element) => element == positionId);
				if(found != undefined) {
					map.save_my_pos(rooms["room25"].tree_back_pos);
					avatar.enter_room("room25");
				}
				found = rooms["room3"].enter_blks.find((element) => element == positionId);
				if(found != undefined) {
					map.save_my_pos(rooms["room3"].tree_back_pos);
					avatar.enter_room("room3");
				}
				found = rooms["room5"].enter_blks.find((element) => element == positionId);
				if(found != undefined) {
					map.save_my_pos(rooms["room5"].tree_back_pos);
					avatar.enter_room("room5");
				}
				found = rooms["room6"].enter_blks.find((element) => element == positionId);
				if(found != undefined) {
					map.save_my_pos(rooms["room6"].tree_back_pos);
					avatar.enter_room("room6");
				}
				found = rooms["room8"].enter_blks.find((element) => element == positionId);
				if(found != undefined) {
					map.save_my_pos(rooms["room8"].tree_back_pos);
					avatar.enter_room("room8");
				}
				if(rooms[_roomName].bounce_cubes.find((element) => element == positionId) != undefined) {
					$('#user'+_userId).addClass( "animated bounce infinite" );
				} else {
					if($('#user'+_userId).hasClass( "animated bounce infinite" ))
						$('#user'+_userId).removeClass( "animated bounce infinite" );
				}
				const f = slide_ladder_start_positions.find((element) => element == positionId);
				if(f != undefined) {
					let tmp1 = f.split('-');
					const axis1 = all_cubes[slide_ladder_end_pos].axis;
					let tmp = slide_ladder_end_pos.split('-');
					const axis = all_cubes[slide_ladder_end_pos].axis;
					const middle_pt = [(axis1[0] + axis[0])/2, (axis1[1] + axis[1])/2];
					$('#user'+_userId).find('.box img').attr('src', _level+'images/avatar/'+_userCharacter+'/walk_down.webp');
					gsap.to($('#user'+_userId), {
						rotation: -45, 
						left : middle_pt[0]+'%', 
						top : middle_pt[1]+'%', 
						duration: (1000*0.001), 
						ease: 'power1.out',
						onComplete: function() {
							gsap.to($('#user'+_userId), {
								rotation: 0, 
								left : axis[0]+'%', 
								top : axis[1]+'%', 
								duration: (500*0.001), 
								ease: 'power1.out',
								onComplete: function() {
									cur_pos = slide_ladder_end_pos;
									cur_pos_js = [parseInt(tmp[0]), parseInt(tmp[1])];
									map.save_my_pos(cur_pos);
									map.scrollToPosition(0);
								}
							});
						}
					});
				} else {
				}
				
			} else {
				if('exit_blks' in rooms[_roomName]) {
					const found = rooms[_roomName].exit_blks.find((element) => element == positionId);
					if(found != undefined)
						return_to_tree(_roomName);
				} else if('exit_blk_range' in rooms[_roomName]) {
					const tmp = positionId.split('-');
					const x = parseInt(tmp[0]);
					const y = parseInt(tmp[1]);
					if(x >= rooms[_roomName].exit_blk_range[0] && x <= rooms[_roomName].exit_blk_range[1] && y >= rooms[_roomName].exit_blk_range[2] && y <= rooms[_roomName].exit_blk_range[3]) {
						return_to_tree(_roomName);
					}
				} else {
					if(_roomName == "room3" && 'modal_blks' in rooms[_roomName]) {
						const found = rooms[_roomName].modal_blks.find((element) => element == positionId);
						if(found != undefined) {
							show_edit_tweet();
						}
					}
				}
			}
		}
		_socket.on('action', function(data){
			//console.log("socket rx", data.type, data);
			switch(data.type)
			{
				case 'add_avatar' :
					avatar.renderCharacter(data);
					break;
				case 'del_avatar' :
					avatar.removeCharacter(data);
					break;
				case 'change_pos' :
					if(_roomName == data.room_name)
						map.startMove(data);
				break;
			}
		});
		_socket.on('dropped', function(data){
			console.log("dropped");
			// disconnect socket
			_socket.disconnect();
			$("#dropped_modal").show();
		});

		// ping
		_socket.on('ping', function(data){
			console.info(data);
		});

		let disconnected = false;
		_socket.on('connect', function(){
			if(!disconnected)
				return;
			if(_userCharacter != null && _userCharacter != "" && _userCharacter != undefined &&
			_userName != null && _userName != "" && _userName != undefined) {
				//console.log(`ws connect, join_room ${_roomName}, pos ${cur_pos}`);
				_socket.emit('join_room', {room_name: _roomName, pos: cur_pos, character: _userCharacter, nickname: _userName} , function() {
					disconnected=false;
				});
			}
		});
		_socket.on('disconnect', function(){
			//alert("ws disconnect");
			disconnected = true;
		});

		// disconnect
		_socket.on('user_disconnected', function(res){
			//console.log('user_disconnected', res);
			if(res.id && res.id !== undefined) {
				$('#user'+res.id).fadeOut('fast', function(){
					$(this).remove();
				});
			}
		});
		// 如果已经选择过角色了，直接进入大树
		if(_userCharacter != null && _userCharacter != "" && _userCharacter != undefined &&
			_userName != null && _userName != "" && _userName != undefined) {
			$('#entrance_btn_a').hide();
			$('#entrance_btn_b').hide();
			$('#entrance_bg_text').hide();
			$("#entrance_wrapper").hide();
			all_cubes = tree_all_cubes;
			avatar.resume_game(_userCharacter, _userName);
		}
		else
		{
			const go_to_tree = function() {
				all_cubes = tree_all_cubes;
				// 默认选择一个角色
				_userCharacter = "charA";
				localStorage.setItem('avatar4', _userCharacter);
				_userName = "ネーム";
				localStorage.setItem('nickname3', _userName);
				avatar.set_user_info(_userCharacter, _userName, function(res) {
					resize_map_to_tree_size();
					$("#map").show();
					avatar.resume_game(_userCharacter, _userName);
				});
			};
			const show_entrance = localStorage.getItem('show_entrance1') || 1;
			if(show_entrance == 0) {
				go_to_tree();
			} else {
				$("#entrance_wrapper").show();
				$('#entrance_btn_a').on('click', function(){
					$('#help_op2').fadeIn();
					return false;
				})
				$('#entrance_btn_b').on('click', function(){
					if(develop) {
						$("#gondola1").show();
						$("#gondola2").show();
						$("#gondola2_rope").show();
					}
					$('#entrance_btn_a').hide();
					$('#entrance_btn_b').hide();
					$('#entrance_bg_text').hide();
					$('#entrance_bg_pic_1').hide();
					all_cubes = entrance_all_cubes;
					// 进入entrance房间，添加一个无名的角色
					_userCharacter = "charA";
					avatar.enter_room("entrance", false, function() {
						$("#grandma").show();
						$("#zoom_in").hide();
						$("#zoom_out").hide();
						$('#grandma_talk .container .btn_yes').on('click', function(){
							$('#grandma_talk_inner1').hide();
							$('#grandma_talk_inner3').fadeIn();
							const _this = $(this);
							setTimeout(function() {
								$("#map").fadeOut(3000, function() {
								window.location.href = "/";
								});
								/*
								gsap.to($('#map-wrapper'), {
									scrollTop : 0, scrollLeft : 0, duration: 0, ease: 'linear',
									onComplete: function() {
										$("#map").hide();
										$('#block_entrance').hide();
										$("#grandma_talk").hide();
										$('#grandma_talk_inner3').hide();
										$('#entrance_btn_a').show();
										$('#entrance_btn_b').show();
										$('#entrance_bg_text').show();
										$('#entrance_bg_pic_1').fadeIn();
									}
								});
								*/

							}, 3000);
							return false;
						});

						$('#grandma_talk .container .btn_no, #grandma_talk .container .btn_no2').on('click', function() {
							localStorage.setItem('show_entrance1', 0);
							$('#grandma_talk_inner1').hide();
							$("#grandma_talk").css("bottom", "42%");
							$('#grandma_talk_inner2').fadeIn();
							$("#gondola1").show();
							if($(window).width() < 640)
								$("#gondola1").animate({bottom: "38.4%"}, 3000);
							else
								$("#gondola1").animate({bottom: "33.5%"}, 3000);
							setTimeout(function() {
								$(".real-person").hide();
								$('#grandma_talk_inner2').hide();
								$("#gondola1").hide();
								$("#grandma").hide();
								$("#gondola2").show();
								$("#gondola2_rope").show();
								/*
								const blockNum = $("#eb83-60").data('num');
								const userOffSetLeft = Math.round( ((all_cubes[blockNum].axis[0] / 100)  * $("#map").width()) - $('#map-wrapper').width() / 2 );
								let userOffSetTop = Math.round( ((all_cubes[blockNum].axis[1] / 100)  * $("#map").height()) - $('#map-wrapper').height() / 2 );
								userOffSetTop -= 128;
								$('#map-wrapper').scrollTop(userOffSetTop);
								$('#map-wrapper').scrollLeft(userOffSetLeft);
								*/
								setTimeout(function() {
									_zoom = 0;
									map.zoomMap(function() {
										map.scrollToPosition3(0, 0, 0);
									});
									$("#gondola2, #gondola2_rope").css("left", "20%");
									$("#gondola2").css("width", "8%");
									$("#gondola2_rope").css("width", "6%");
									$("#gondola2").css("bottom", "48%");
									$("#gondola2_rope").css("bottom", "50.8%");
									$("#gondola2_rope").animate({bottom: "58%"}, 1500);
									$("#gondola2").animate({bottom: "56%"}, 3000, function() {
										localStorage.setItem("tree_pos3", rooms["tree"]['def_avatar_pos']);
										$("#entrance_to_tree_bk").css('display', 'flex');
										return_to_tree("entrance", "down", 0, 3000, function() {
											$("#entrance_to_tree_bk").hide();
											setTimeout(function() {
												_zoom = 2;
												map.zoomMap(function() {
													map.scrollToPosition(0);
													$("#menu_btn").show();
													menu_btn_tooltip_setup();
													$("#map_overview").show();
													$("#location").show();
													$("#zoom_in").show();
													$("#zoom_out").show();
													$('#user'+_userId).show();
													setTimeout(function() {
														questionnaire_setup();
													}, 1000);
												});
											}, 3000);
										});
									});
								}, 2000);
							}, 5000);
							return ;false;
						})
					});

					return false;
				})
			}
		}
		$("#menu_avatar_btn, #room1_customize_btn, #room1_customize_btn").click(function(){
			$("#menu").removeClass('show');
			if($(window).width() < 640) {
				$("#avatar_sp").find('input[name="nickname"]').val(_userName);
				$("#avatar_sp").find('input[name="nickname"]').val(_userName);
				$("#avatar_sp").fadeIn();
			} else {
				$("#avatar").find('input[name="nickname"]').val(_userName);
				$("#avatar").find('input[name="nickname"]').val(_userName);
				$("#avatar").fadeIn();

			}
			return false;
		});
		$("#avatar .overlay, #avatar .close").click(function(){
			$("#avatar").hide();
			return false;
		});

		$("#menu_help_btn").click(function(){
			$("#menu").removeClass('show');
			$("#help_op").fadeIn();
			return false;
		});
		$("#menu_help_btn2").click(function(){
			$("#menu").removeClass('show');
			$("#help_op2").fadeIn();
			return false;
		});
		$("#map_overview").click(function(){
			$(".room_shortcuts").show();
			if(_roomName != "tree")
				$(`#${_roomName}_shortcut`).hide();
			$("#map_overview_modal").fadeIn();
			return false;
		});

		$(".help_modal_right_btns").click(function(){
			$(".help_modal").fadeOut();
			return false;
		});				

		var cur_category_line_id = "0";
		var my_recommend_categories = [0, 1, 2];
		$(".show_classes_btns").click(function(){
			cur_category_line_id = $(this).data('target');
			$("#recomm_cate_room8").show();
			return false;
		});				
		$(".category_btns").click(function(){
			$(`#choose_line${cur_category_line_id+1}_btn`).html(`<picture style="width: 100%;">
					<source srcset="/static/scenes/main/files/categories/icon_cat_${categories_names[parseInt($(this).data('target'))]}.webp" type="image/webp">
					<img src="/static/scenes/main/files/categories/icon_cat_${categories_names[parseInt($(this).data('target'))]}.png" alt="BL80_category_palette" style="width: 100%;" loading="lazy">
				</picture>`);
			my_recommend_categories[parseInt(cur_category_line_id)] = $(this).data('target');
			$("#recomm_cate_room8").hide();
			return false;
		});				
		$('#submit_suggestion_btn').on('click', function(){
			if($('#edit_recommend_room8 textarea.line4').val().length <= 0)
				return;
			$('#edit_recommend_room8').animate({left: "56%", top: "26.5%", width: "0%", height: "0%"}, 500).fadeOut(function() {
				$('#edit_recommend_room8').css('top', "0%");
				$('#edit_recommend_room8').css('left', "0%");
				$('#edit_recommend_room8').css('width', "100%");
				$('#edit_recommend_room8').css('height', "100%");
			});
			const d = {
				type	: "post",
				url		: '/update_recommendation',
				data	: {
					'_id'		: my_recommend_id,
					'text1'		: $('#edit_recommend_room8 textarea.line1').val(),
					'text2'		: $('#edit_recommend_room8 textarea.line2').val(),
					'text3'		: $('#edit_recommend_room8 textarea.line3').val(),
            		'content' 		: $('#edit_recommend_room8 textarea.line4').val(),
					'name'		: $("#edit_recommend_room8 input").val(),
					'category1'	: my_recommend_categories[0],
					'category2'	: my_recommend_categories[1],
					'category3'	: my_recommend_categories[2],
				}
			};
			console.log(d);
			$.ajax(d).done(function(res) {
			}).fail(function(e){
			});
		});

		$(".map_label_modal .help_modal_left_btns").click(function(){
			$("#map_overview_modal").hide();
			$(".help_modal").fadeOut();
			const btn_id = `#enter_${$(this).parent().parent().parent().data('room')}_btn`;
			if(_roomName == 'tree') {
				if($(btn_id).data("pos") == cur_pos) {
					avatar.enter_room($(this).parent().parent().parent().data('room'));
				} else {
					console.log($(btn_id).data('pos'));
					$(".block" + $(btn_id).data('pos')).trigger('click');
				}
			} else {
				if(_roomName == $(this).parent().parent().parent().data('room'))
					return false;
				target_room_after_exit_room = $(this).parent().parent().parent().data('room');
				// 已经在某个房间里面，先退出，再进入新房间
				if('exit_blks' in rooms[_roomName])
					$(".block" + rooms[_roomName].exit_blks[0]).trigger('click');
				else if('exit_blk_range' in rooms[_roomName])
					$(`.block${rooms[_roomName].exit_blk_range[0]}-${rooms[_roomName].exit_blk_range[1]}`).trigger('click');
			}
			return false;
		});				


		$("#enter_room1_btn, #room1_shortcut").click(function(){
			console.log('room1 btn clicked', $(this).data('pos'));
			$("#map_label_room1").fadeIn();
			return false;
		});

		$("#enter_room2_btn, #room2_shortcut").click(function(){
			console.log('room2 btn clicked', $(this).data('pos'));
			$("#map_label_room2").fadeIn();
			return false;
		});
		$("#enter_room25_btn, #room25_shortcut").click(function(){
			console.log('room25 btn clicked', $(this).data('pos'));
			$("#map_label_room25").fadeIn();
			return false;
		});

		$("#enter_room3_btn, #room3_shortcut").click(function(){
			$("#map_label_room3").fadeIn();
			return false;
		});
		$("#enter_room4_btn, #room4_shortcut").click(function(){
			$("#map_label_room4").fadeIn();
			return false;
		});
		$("#enter_room7_btn, #room7_shortcut").click(function(){
			$("#map_label_room7").fadeIn();
			return false;
		});
		$("#enter_room5_btn, #room5_shortcut").click(function(){
			//console.log('room5 btn clicked', $(this).data('pos'));
			$("#map_label_room5").fadeIn();
			return false;
		});
		$("#enter_room6_btn, #room6_shortcut").click(function(){
			//console.log('room6 btn clicked', $(this).data('pos'));
			$("#map_label_room6").fadeIn();
			return false;
		});
		$("#enter_room8_btn, #room8_shortcut").click(function(){
			//console.log('room8 btn clicked', $(this).data('pos'));
			$("#map_label_room8").fadeIn();
			return false;
		});

	});

});

const ChatUtil =
{
    chat: function () {
        const message = $("#chatText").val();
		$.ajax({
            type	: "post",
            url		: '/r25_add_sent_msg',
            data	: {
				'text'		: message,
            }
        }).done(function(res) {
			//const t = new Date();
			var o = "";//t.getMonth() + 1 + "/" + t.getDate() + " " + t.getHours().toString().padStart(2, "0") + ":" + t.getMinutes().toString().padStart(2, "0");
			$("#chat_list").find("ul").append(`<li class="right"><div class="pic"><img src="/static/scenes/main/images/avatar/${_userCharacter}/main_left.webp" width="90" /></div><div class="box"><p class="info" style="color:#000000">あなた <span>` + o + '</span></p><p class="text">'+message+'</p></div></li>');
			$("#chat_list").find("ul").append('<li class="left"><div class="pic"><img src="/static/scenes/main/files/BL25_anime_01.webp" width="90" /></div><div class="box"><p class="info" style="color:#000000">むかんけいロボ <span>' + o + '</span></p><p class="text tmpMsg ai_typing"></p></div></li>');
			ChatUtil.getChatResult(message).then((rst) =>{
				if(rst == null)
					return;
				$.ajax({
					type	: "post",
					url		: '/r25_add_recv_msg',
					data	: {
						'text'		: rst,
					}
				}).done(function(res) {
					$(".tmpMsg").removeClass("ai_typing");
					$(".tmpMsg").text(rst);
					$(".tmpMsg").removeClass("tmpMsg");
					ChatUtil.scrollToEnd();
				});
			});
			$("#chatText").val("");
			ChatUtil.scrollToEnd();
        }).fail(function(e){
        });

    },

    getChatResult: async function (message) {
        try {
          const response = await fetch('http://13.112.110.151:7890/chat', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ message: message })
          });
          const data = await response.json();
          console.log("response:")
          console.log(data)
          const result = data.result;
          return result;
        } catch (error) {
          console.error('Error fetching data:', error);
		  return null;
        }
    },

    scrollToEnd: function(){
      $('#chat_history').animate({scrollTop:$('#chat_history')[0].scrollHeight}, 1000);
    }
}
$(document).ready(function() {
  if($("#chat_list").length > 0){
    $("#chat_list").find('ul').empty();
  }
  $("#sendChat").click(ChatUtil.chat);
});
